import { useEffect, useState } from 'react'; 
import { Book, BookOpen, Briefcase, Calendar, CreditCard, CheckSquare, DollarSign, Dribbble, Facebook, Globe, Instagram, Mail, MapPin, Menu, MousePointer, Phone, Share2, ThumbsUp, Twitch, Twitter } from 'react-feather'
import moment from 'moment'
import PostApiCall from './Api/Api'
import {Helmet} from "react-helmet";

function SettleAbroad() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [subject, setSubject] = useState('Settle Abroad')
    const [message, setMessage] = useState('')
    const [buttonLocked, setButtonLocked] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')




    const sendMailer = () => {
								
											setErrMsg('')
											if(name != '' && email != '' && mobile != '' && subject != '' && message != ''){
												setButtonLocked(true)
												PostApiCall.postRequest({
													name, 
													email,
													mobile,
													subject,
                                                    message
												  }, "QueryMailer").then((results) => results.json().then(obj1 => {
											  
													if (results.status === 200 || results.status === 201) {
														
                                                        setName('')
                                                        setEmail('')
                                                        setMobile('')
                                                        setSubject('')
                                                        setMessage('')
                                                        setSendSuccess(true)
                                                        setButtonLocked(false)
                                                        
													}else {
                                                        setButtonLocked(false)
                                                        setErrMsg('Something wnet wrong, try again later.')
														
													}
												}))


											}
									
    }

  return (
    <div className="App">

<Helmet>
        <title>Best Immigration in Overseas Consultants Delhi for Canada PR | Best Visa Agents in Faridabad</title>
        <meta name="description" content="Contact to ShiftAbroad Immigration Overseas consultant in Faridabad, Delhi. We are Visa consultants and immigration experts in Faridabad with best services." />
        <meta keywords="Best Immigration in Delhi for Canada pr, Overseas Consultants Delhi, Best Visa Agents in Faridabad" />
    </Helmet>
    
    <div class="app-landing-wrap landing_wrap">
            <div id="landing_wrap" class="landing-gradient-red-orange">

            
                {/* <!-- ============ Main content start ============= --> */}
                <div class="main-content-wrap">

                    {/* <!-- intro-section --> */}

                    <section id="intro-wrap" class="intro1Wrap1 text-left text-white">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 intro1LeftSection pb-5" style={{margin : 'auto'}}>
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">SETTLE ABROAD</h1>
                                   
                                   
                                    <div class="intro1Buttons" style={{margin : 'auto'}}>
                                       
                                        <a id="" href="/settleabroad/#contact" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                            Contact us now
                                        </a>

                                    </div>

                                </div>
                                <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="/assets/images/settlebanner4.png" class="img-responsive" alt=""  style={{paddingTop : '30px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay">

                        </div>

                    </section>
                    {/* <!-- end::intro-section --> */}

 {/* <!-- start::contact-form --> */}
 <section id="contacts-wrap" class="contact-form-wrap" style={{paddingBottom : '0px',paddingTop : '50px'}}>
                        <div class="container">
                            <div class="row">



                                <div data-aos="fade-right" class="form-wrap col-md-12 mb-4">
                                    <div class="section-header mb-5">
                                        <h2 class="font-weight-bold" style={{marginTop : '0px'}}>Create your dream home with us!</h2>
                                        <p> ShiftAbroad will help you to find the country of your choice where you can have secure, reliable, progressive life ahead. ShiftAbroad experts will advise you based on our experience & market updates on various subjects such as intergovernmental, non-governmental organizations, stakeholders, facilities, economic & social development, migration related programmes aimed at maximizing migrations benefits.</p>

                                    </div>
                                  


                                </div>
                              
                            </div>
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}
 {/* <!-- start::contact-form --> */}
 <section id="" class="right-image-wrap light-gray p-t-b-80" style={{ paddingTop: '80px',
    paddingBottom: '0px'}}>
                        <div class="container">


                        <div class="row text-center">
                                    <div class="col-md-12 mb-5">
                                        <h2 class="font-weight-bold">Why Choose us?</h2>
                                        <p class="text-center">Our dedicated team of highly experienced 
                                            professionals will soothen the process of creating your new home in a country of your dreams</p>

                                    </div>

                                    <div class="col-md-6 col-lg-6 p-5 mb-2">
                                    <Calendar size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Meeting</h3>
                                        <p class="card-text">Understanding the client requirement & suggest the best suitable country to shift on all the factors of eligibility.</p>
                                    </div>
                                    <div class="col-md-6 col-lg-6 p-5 mb-2">
                                    <MousePointer size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Apply</h3>
                                        <p class="card-text">Our experts will help your prepare documentation & help you visit the country in order to fulfill the legal formalities including visa application.</p>
                                    </div>
                                    <div class="col-md-6 col-lg-6 p-5 mb-2">
                                    <CreditCard size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Resident Card</h3>
                                        <p class="card-text">Once everything is in place & legally approved, one can apply for resident card of the country for a certain period of time.</p>
                                    </div>

                                    <div class="col-md-6 col-lg-6 p-5 mb-2">
                                    <Book size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Passport</h3>
                                        <p class="card-text">Once you get he Resident Card, you are eligible to apply for the passport. Upon successfull completion of all the documentation & formalities, one can receive a passport. </p>
                                    </div>

                                   

                                </div>

                    

                            
                        </div>
                    </section>


                    <section id="subscribe-wrap" class="subscribe p-t-b-80">
                        <div class="container">



                            <div class="row text-center">
                                {/* <!-- start::subscribe-1 --> */}
                                <div data-aos="fade-up" class="col-md-12 aos-init aos-animate">
                                    <div class="subscribe-1">
                                        <div class="card back-color-panel" style={{padding : '40px'}}>
                                            
                                            <div class="card-body">
                                            <div class="row">
                                            <div class="col-md-6">
                                        
                                            Send us a message
                                            <h2 class="font-weight-bold">It's never too late to build a home, no limit to choose a place. Get your dream home in a country that suits you.</h2>
                                    </div>
                                    <div class="col-md-6" style={{margin : 'auto'}}>
                                        
                                    <div class="intro1Buttons" style={{margin : 'auto'}}>
                                       
                                       <a id="" href="/contactus" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" href="#" role="button">

                                           Contact us now
                                       </a>

                                   </div>
                                           </div>
                                            </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end::subscribe-1 --> */}
                            </div>

                        </div>
                    </section>


                     {/* <!-- start::contact-form --> */}
 <section id="contact" class="contact-form-wrap light-gray p-t-b-80" style={{ paddingTop: '80px',
    paddingBottom: '50px'}}>
                        <div class="container">

                            <div class="row">

                            <div data-aos="fade-right" class="form-wrap col-md-5 mb-4">
                                {/* <div class="col-lg-6 offset-lg-1"> */}
                                        {/* <h2 class="font-weight-bold">Some attractive benefits provide</h2>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident eveniet
                                            illo,
                                            placeat dolorum delectus nam error itaque ratione facilis quod praesentium.
                                        </p> */}

<img src={'/assets/images/settlecontact.png'} /> 
                                    {/* </div> */}
                                    </div>

                            <div data-aos="fade-right" class="form-wrap col-md-1 mb-4"></div>

                                <div data-aos="fade-right" class="form-wrap col-md-6 mb-4">
                               
                                    {/* <div *ngif="success" class="alert alert-success alert-dismissible fade show" role="alert" 
                                    [@animate]="{value:'*',params:{y:'-120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
                                        <button type="button" class="close" onclick="success=false">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <strong>Your Message Sent succefully</strong>
                                    </div> */}


                                    <form class="row form" role="form" onSubmit={(e)=>{
                                        e.preventDefault()
                                    }}>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-person-outline"></i>
                                            </label>
                                            <input type="text" name="Name" id="" class="form-control" placeholder="Your Name" required
                                            value={name}
                                            onChange={(text)=>{
                                                setName(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-email-outline"></i>
                                            </label>
                                            <input type="email" name="email" class="form-control" placeholder="Your email" aria-describedby="helpId" required
                                            value={email}
                                            onChange={(text)=>{
                                                setEmail(text.target.value)
                                            }}
                                            />

                                        </div>
                                       

                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-phone-outline"></i>
                                            </label>
                                            <input type="mobile" required name="mobile" class="form-control" placeholder="Your mobile" aria-describedby="helpId" 
                                            value={mobile}
                                            onChange={(text)=>{
                                                setMobile(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-edit-2-outline"></i>
                                            </label>
                                            {/* <input type="text" required name="subject" class="form-control" placeholder="Your subject here" aria-describedby="helpId" /> */}
                                            <input type="text" required name="subject" class="form-control" value={'Study Abroad'} disabled placeholder="Your subject here" aria-describedby="helpId" />
                                           
                                        </div>
                                        <div class="form-group mb-4 col-md-12">

                                            <label for="">
                                                <i class="eva eva-edit-outline"></i>
                                            </label>
                                            <textarea class="form-control" placeholder="Your message here" name="message" rows="5"
                                            value={message}
                                            onChange={(text)=>{
                                                setMessage(text.target.value)
                                            }}
                                            ></textarea>


                                        </div>

                                        <div class="form-group col-md-12">
                                            <button type="submit"
                                            disabled={buttonLocked}
                                            onClick={()=>{
                                                sendMailer()
                                            }}
                                            class="btn btn-block half-button form-submit-button btn-large btn-gradient">
                                                {buttonLocked ? 'Sending...' : 'Send Message'}
                                                
                                            </button>
                                            <p style={{color : 'red'}}>{errMsg}</p>
                                        </div>



                                    </form>
                                    <div style={{textAlign : 'center', display : sendSuccess ? '' : 'none'}}>
					<p style={{color : 'green', textAlign : 'center'}}><CheckSquare  size={15}/>&nbsp;
					Thank you for your message! We will get back to you soon.</p>
					</div>




                                </div>
                              
                    
                              
                            </div>

                            
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}
                </div>
                {/* <!-- ============ Main content End ============= --> */}




            </div>
        </div>
    </div>
  );
}

export default SettleAbroad;
