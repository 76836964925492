import { BookOpen, Briefcase, DollarSign, Dribbble, Facebook, Globe, Instagram, Mail, MapPin, Menu, Phone, Share2, ThumbsUp, Twitch, Twitter, Youtube } from 'react-feather'
import moment from 'moment'

function Footer() {
  return (
    <div className="App">
    
    <div class="app-landing-wrap landing_wrap">
            <div id="landing_wrap" class="landing-gradient-red-orange">

        
                {/* <!-- ============ Main content start ============= --> */}
                <div class="main-content-wrap">
                    {/* <!-- Footer Start --> */}
                    <div class="flex-grow-1"></div>
                 
                    <section class="footer_wrap" id="footer-wrap">
                        <div class="container">
                            <div class="row">


                                <div data-aos="fade-up" class="col-md-5 mb-4">
                                    <div class="o-hidden text-left">



                                        <div class="">
                                            <h4 class="card-title font-weight-bold">ShiftAbroad</h4>
                                            <p class="">At ShiftAbroad, we ease our client’s journey to move to a country of their choice. We are a team of highly experienced professionals who have been in the industry for more than two decades. This dedicated team helps individuals with customised advice in accordance with the legal framework of the country they choose. We mentor to help, make informed to take decisions based on the legal, economic and social requirements of the prospective country of residence. We have pool of experienced team with experience of Embassies, Travel & Recruitment Agencies. </p>
                                        </div>


                                    </div>
                                </div>
                                {/* <!-- end of col --> */}
                                <div data-aos="fade-up" class="col-md-2 mb-4">
                                    <div class="o-hidden text-left">



                                        <div class="">
                                            <h4 class="card-title font-weight-bold">Pages</h4>
                                            <div class="mb-5 lead">
                                                <ul class="list-inline">
                                                    <li class="mb-3"> <a href="/">Home</a></li>
                                                    <li class="mb-3"><a href="/aboutus">About us</a>
                                                    </li>
                                                    <li class="mb-3"> <a href="/privacypolicy"> Privacy Policy</a>
                                                    </li>
                                                    <li class="mb-3"> <a href="/contactus"> Contact us</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                               
                                {/* <!-- end of col --> */}
                                <div data-aos="fade-up" class="col-md-2 mb-4 mb-lg-0 mb-md-0">
                                    <div class="o-hidden text-left">
                                    <div class="">
                                            <h4 class="card-title font-weight-bold"><br/></h4>
                                            <div class="mb-5 lead">
                                            <ul class="list-inline">
                                            <li class="mb-3"> <a href="/studyabroad"> Study Abroad</a>
                                                    </li>
                                                    <li class="mb-3"> <a href="/workabroad">Work Abroad</a></li>
                                                    <li class="mb-3"><a href="/settleabroad">Settle Abroad</a>
                                                    </li>
                                                    
                                                    <li class="mb-3"> <a href="/topcountries">Top Countries</a>
                                                    </li>
                                                    </ul>
                                            </div>


                                        </div>
                             
                                    </div>
                                </div>
                                {/* <!-- end of col --> */}
                                <div data-aos="fade-up" class="col-md-3 mb-lg-0 mb-md-0">
                                    <div class="o-hidden text-left">

                                        <div class="">
                                            <h4 class="card-title font-weight-bold">Contact Info</h4>
                                            <div class="address-wrap mb-3">

                                                <ul class="list-group list-unstyled">
                                                    <li class=""> <i class="eva eva-pin-outline mr-2"></i>
                                                    <span>E 85, 2nd Floor, Sector 11, <br/><span style={{paddingLeft : '24px'}}>Faridabad 121006, India.</span></span>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div class="address-wrap mb-3">

                                                <ul class="list-group list-unstyled">
                                                    <li class=""><i class="eva eva-email-outline mr-2"></i>shift@shiftabroad.in
                                                    </li>

                                                </ul>
                                            </div>

                                            <div class="address-wrap mb-3">

                                                <ul class="list-group list-unstyled">
                                                    <li class="">

                                                        <i class="eva eva-phone-call-outline mr-2"></i>
                                                   <span> 
                                                    {/* <a href="Tel:+919971185324">+91 997 118 5324</a> */}
                                                    <a href="Tel:+919971185324">+91 981 844 4514</a>

                                                    </span>
                                                    </li>

                                                </ul>
                                            </div>


                                            <section class="social-wrap">

                                                <div class="social-btns-wrap">

                                                    <a class="social-btn text-30 ml-0" style={{backgroundColor : '#3b5998'}} href="https://www.facebook.com/Shiftabroad-101759515732309/" target={'_blank'}><Facebook color={'#fff'}/></a>
                                                    <a class="social-btn text-30 instagram-icon" href="https://instagram.com/shiftabroad?utm_medium=copy_link" target={'_blank'}><Instagram color={'#fff'} /></a>
                                                    <a class="social-btn text-30" href="https://www.youtube.com/channel/UC7Ya9O4GrcZRZcl-hA46Y-g" style={{backgroundColor : '#ff0000'}} target={'_blank'}><Youtube color={'#fff'}/></a>


                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end of col --> */}
                            </div>
                        </div>
                    </section>
                    {/* <!-- end footer --> */}

                    <section class="copyright-section">
                ©{moment().format('YYYY')} <span>ShiftAbroad  </span> powered by  <span>Atlast Management Services</span>. All Rights Reserved
            </section>

                </div>
                {/* <!-- ============ Main content End ============= --> */}




            </div>
        </div>
    </div>
  );
}

export default Footer;
