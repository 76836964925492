import { useEffect, useState } from 'react'; 
import { BookOpen, Briefcase, DollarSign, Dribbble, Facebook, CheckSquare, Globe, Instagram, Mail, MapPin, Menu, Phone, Share2, ThumbsUp, Twitch, Twitter } from 'react-feather'
import moment from 'moment'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import PostApiCall from './Api/Api'
import {Helmet} from "react-helmet";
// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

function Home() {

    
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [buttonLocked, setButtonLocked] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')




    const sendMailer = () => {
								
											setErrMsg('')
											if(name != '' && email != '' && mobile != '' && subject != '' && message != ''){
												setButtonLocked(true)
												PostApiCall.postRequest({
													name, 
													email,
													mobile,
													subject,
                                                    message
												  }, "QueryMailer").then((results) => results.json().then(obj1 => {
											  
													if (results.status === 200 || results.status === 201) {
														
                                                        setName('')
                                                        setEmail('')
                                                        setMobile('')
                                                        setSubject('')
                                                        setMessage('')
                                                        setSendSuccess(true)
                                                        setButtonLocked(false)
                                                        
													}else {
                                                        setButtonLocked(false)
                                                        setErrMsg('Something wnet wrong, try again later.')
														
													}
												}))


											}
									
    }


  return (
    <div className="App backdrop-dark">

<Helmet>
        <title>Top 10 Overseas Education Study Abroad Consultants in Delhi India - ShiftAbroad</title>
        <meta name="description" content=": ShiftAbroad provides Overseas education consultants in Delhi offer customized solutions based on the specific needs of a student. Visit Now at ShiftAbroad" />
        <meta keywords="Best Agency to Study Abroad, Abroad Education Consultants in India, Best Education Consultants in India, Top 10 Overseas Education Consultants in India, Study Abroad Consultants Delhi" />
    </Helmet>
    
    <div class="app-landing-wrap landing_wrap">
            <div id="landing_wrap" class="landing-gradient-red-orange">

            
                {/* <!-- ============ Main content start ============= --> */}
                <div class="main-content-wrap">

                    {/* <!-- intro-section --> */}

                    <section id="intro-wrap" class="intro1Wrap text-center text-white">
                        <div class="container">
                            {/* <div class="row"> */}
                            <Swiper
					spaceBetween={30} centeredSlides={true} loop={true} autoplay={{
						"delay": 3000,
						"disableOnInteraction": false
					  }} className="mySwiper"
					>
					<SwiperSlide>
                        <div class='row'>
                    <div class="col-md-12 intro1LeftSection pb-5">
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">Study Abroad</h1>
                                    <p class="text-20 mb-4 text-center" >Pursue your dream career abroad with us </p>
                                   
                                    <div class="intro1Buttons">
                                       
                                        <a id="" href="/contactus"
                                        style={{background : '#000258', border : '1px solid #000258', color : '#fff'}}
                                        class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                            Contact us now
                                        </a>

                                    </div>

                                
                               
                                </div>
                                 {/* <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="http://demos.ui-lib.com/dexam-angular-html/assets/images/landing/svg/v1-header.svg" class="img-responsive" alt="" />
                                    </div>
                                </div> */}
                                </div>
                    </SwiperSlide>
					<SwiperSlide>
                    <div class='row'>
                    <div class="col-md-12 intro1LeftSection pb-5">
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">Work Abroad</h1>
                                    <p class="text-20 mb-4 text-center">Get a job overseas that suits you best </p>
                                   
                                    <div class="intro1Buttons">
                                       
                                        <a id="" 
                                        style={{background : '#000258', border : '1px solid #000258', color : '#fff'}}
                                        href="/contactus" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                            Contact us now
                                        </a>

                                    </div>

                                
                               
                                </div>
                                 {/* <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="http://demos.ui-lib.com/dexam-angular-html/assets/images/landing/svg/v1-header.svg" class="img-responsive" alt="" />
                                    </div>
                                </div> */}
                                </div>
                    </SwiperSlide>
					<SwiperSlide> <div class='row'>
                    <div class="col-md-12 intro1LeftSection pb-5">
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">Settle Abroad</h1>
                                    <p class="text-20 mb-4 text-center">Create your new home anywhere in the world with us </p>
                                   
                                    <div class="intro1Buttons">
                                       
                                        <a id="" 
                                        style={{background : '#000258', border : '1px solid #000258', color : '#fff'}}
                                        href="/contactus" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                            Contact us now
                                        </a>

                                    </div>

                                
                               
                                </div>
                                 {/* <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="http://demos.ui-lib.com/dexam-angular-html/assets/images/landing/svg/v1-header.svg" class="img-responsive" alt="" />
                                    </div>
                                </div> */}
                                </div></SwiperSlide>
						
					</Swiper>
                                
                                
                            </div>
                        {/* </div> */}
                        <div class="overlay1">

                        </div>

                    </section>
                   
                 {/* <!-- end::intro-section --> */}


   {/* <!-- start-services --> */}
   <section id="services-wrap" class="services-wrap" style={{paddingBottom : '0px'}}>
                    <div class="container">
                            <div class="row">
                                <div class="col-md-12 section-header mb-5">
                                    <h2 class="font-weight-bold">We’re Taking That <a href='/aboutus' style={{color : '#fff'}}>Story</a> To The Next New!!!</h2>
                                    At ShiftAbroad, we ease our client’s journey to move to a country of their choice. We are a team of highly experienced professionals who have been in the industry for more than two decades. This dedicated team helps individuals with customised advice in accordance with the legal framework of the country they choose. We mentor to help, make informed to take decisions based on the legal, economic and social requirements of the prospective country of residence. We have a pool of experienced team with experience of Embassies, Travel & Recruitment Agencies.

                                </div>
                                {/* <div class="col-md-6 col-sm-12 mb-5 mb-lg-0 mb-md-0 mt-mb-5"> */}



                                    {/* <div class="left-image-carousel">

                                       
                                        <div>
                                            <img alt="Card image cap" class="img-responsive mb-4" src="https://img.freepik.com/free-vector/businessman-top-looking-into-telescope-employees-business-opportunity-bizopp-franchising-distribution-concept-white-background_335657-2028.jpg?size=626&ext=jpg" />
                                        </div>
                                        
                                      
                                    </div> */}
                                    {/* <div class="slick_control_wrap text-center">


                                        <button type="button" class="ngu_control_button half-button leftRs btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></button>
                                        <button type="button" class="ngu_control_button half-button rightRs btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                    </div> */}

                                {/* </div> */}



                                <div class="col-md-12 col-sm-12">
                                    <div class="row">


                                        <div data-aos="fade-left" class="col-md-4 col-sm-6 mb-4">
                                            <div class="card">

                                                <div class="card-body pt-3 pb-3" style={{height: '106px'}}>

                                                    <h4 class="card-title font-weight-bold">Vision
                                                    </h4>
                                                    <p class="m-0">We help you contruct your dream into reality.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end of col  --> */}
                                        <div data-aos="fade-right" class="col-md-4 col-sm-6 mb-4">
                                            <div class="card">

                                                <div class="card-body pt-3 pb-3">

                                                    <h4 class="card-title font-weight-bold">Mission
                                                    </h4>
                                                    <p class="m-0">We aim to support individuals and families move to the country of their choice.

                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end of col  --> */}
                                        <div data-aos="fade-up" class="col-md-4 col-sm-6 mb-lg-0 mb-md-0 mb-sm-0">
                                            <div class="card">

                                                <div class="card-body pt-3 pb-3">

                                                    <h4 class="card-title font-weight-bold">Purpose</h4>
                                                    <p class="m-0">We empower our clients with information, legal advice 
and logistics support.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end of col  --> */}
                                    </div>
                                </div>
                                {/* <!-- end of col6 row --> */}
                            </div>
                        </div>
                    </section>
                    {/* <!-- end-services --> */}

                   
                    {/* <!-- our-works --> */}
                    <section id="works-wrap" class="work-carousel-wrap  light-gray">
                        <div class="container">
                            <div class="row">
                                <div class="section-header col-md-12 mb-5">
                                    <h2 class="font-weight-bold">Our Services</h2>
                                    <p>We support all opportunities to migrate
                                    </p>
                                </div>
                                <div class="col-md-12 text-center">
                                    <div data-aos="fade-up" class="slick-work-carousel-wrap">

                                    <div class="card text-left o-hidden mb-4">

<div class="card-body">
    <img alt="Card image cap" class="card-img-top mb-4" src="/assets/images/homestudy.jpg" />
    <h5 class="card-title font-weight-bold">STUDY ABROAD</h5>
    <p class="card-text">
    ShiftAbroad will help you to find various universities worldwide & get you to the 
city of your choice. <br/>We are pioneers in providing one stop solution for your all-global study requirements.  
Our association with many reputed institutions worldwide would be guiding & assisting 
you in all the processes. 

    </p>
</div>
<div class="card-footer pl-3">
   
</div>
</div>

                                    <div class="card text-left o-hidden mb-4">

<div class="card-body">
    <img alt="Card image cap" class="card-img-top mb-4" src="/assets/images/homework.jpg" />
    <h5 class="card-title font-weight-bold">WORK ABROAD</h5>
    <p class="card-text">
    ShiftAbroad, with years of experience & vast network strives to provide job opportunities
across various sectors, globally. Since past two decades demand for Indian talents has grown 
tremendously worldwide & this trend is still continuing, bringing more opportunities. We will keep 
updating work opportunities in many sectors & countries. 


    </p>
</div>
<div class="card-footer pl-3">
   
</div>
</div>
                                        <div class="card text-left o-hidden mb-4">

                                            <div class="card-body">
                                                <img alt="Card image cap" class="card-img-top mb-4" src="/assets/images/homesettle.jpg" />
                                                <h5 class="card-title font-weight-bold">SETTLE ABROAD</h5>
                                                <p class="card-text">
                                                ShiftAbroad will help you find a country of your choice. We will advise you best based on our experience & market analysis on various subjects such as intergovernmental, non-governmental organizations, stakeholders, facilities, economic & social development, migration related programmes aimed at maximizing migrations benefits.
                                                </p>
                                            </div>
                                            <div class="card-footer pl-3">
                                               
                                            </div>
                                        </div>

                                      


                                       
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    {/* <!-- end::our-works --> */}

                 

               



 {/* <!-- start-features --> */}
 <section id="features-wrap" class="features_wrap p-t-b-80">
                        <div class="container">
                            <div class="row">


                                <div class="col-md-12 section-header mb-5">
                                    <h2 class="font-weight-bold">What You Can Expect From Us</h2>
                                    {/* <p> */}
                                    Transparency & Straightforwardness are the premise of our trust, and we are fully eligible when you set the parameters like cost, compliance and report. We have a group of specialists who have core abilities in their specific space gained over long stretches of involvement.
An authentic thank you is uncommon, however it's an exceptionally robust and is a positive method for building a stronger relationship with individuals who truly matter for us as our client. We are proud to offer able valuing at every level of commitment.

                                    {/* </p> */}

                                </div>


                                <div class="col-md-12 col-lg-12 col-sm-12 mb-4 mb-lg-0">
                                    <div class="row">



                                        <div data-aos="fade-up" class="col-md-3 col-lg-3 col-sm-6 mb-4">
                                            <div class="card">

                                                <div class="card-body pt-4 pb-4 text-center">
                                                    <i class="eva eva-clock-outline text-30"></i>
                                                    <h4 class="card-title pt-2 text-16">Knowledge</h4>

                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" class="col-md-3 col-lg-3 col-sm-6 mb-4">
                                            <div class="card">

                                                <div class="card-body pt-4 pb-4 text-center">
                                                    <i class="eva eva-file-text-outline text-30"></i>
                                                    <h4 class="card-title pt-2 text-16">Transparency</h4>

                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" class="col-md-3 col-lg-3 col-sm-6 mb-4">
                                            <div class="card">

                                                <div class="card-body pt-4 pb-4 text-center">
                                                    <i class="eva eva-shopping-bag-outline text-30"></i>
                                                    <h4 class="card-title pt-2 text-16">Expertise</h4>

                                                </div>
                                            </div>
                                        </div>
                                        <div data-aos="fade-up" class="col-md-3 col-lg-3 col-sm-6 mb-4 mb-lg-0">
                                            <div class="card">

                                                <div class="card-body pt-4 pb-4 text-center">
                                                    <i class="eva eva-lock-outline text-30"></i>
                                                    <h4 class="card-title pt-2 text-16">Pricing</h4>

                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                {/* <!-- end of col-6 --> */}
                                {/* <div data-aos="fade-left" class="col-md-6 col-lg-5 col-sm-12 offset-lg-1 d-flex align-items-center">

                                    <img src="/assets/images/values.png" class="img-responsive w-100" alt="" />

                                </div> */}

                            </div>
                        </div>
                    </section>
                    {/* <!-- end-features --> */}

                    {/* <!-- start::right-img --> */}
                    <section class="right-image-wrap p-t-b-80 light-gray">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 section-header ">
                                    <h2 class="font-weight-bold">Simple Steps, Hassle Free Process</h2>
                                    {/* <p>We align a dedicated Planning and sourcing point of contact to each opportunity to ensure seamless communication and constant traction.</p> */}

                                </div>

                                <div class="col-md-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
                                    <div class="row m-0">
                                    <div data-aos="fade-right" class="mb-4">
                                    We align a dedicated Planning and sourcing point of contact to each opportunity to ensure seamless communication and constant traction. <br/><br/>
                                    Our Expertise at ShiftAbroad with understanding of the global job market, the Indian talent pool, and compliance requirements for employment migration is best to make the clients happy & make their dreams come true!!
We have visa experts, and migration consultants on board to help prospective employees understand the requirements and assist them with applications.
                                              </div>

                                        <div data-aos="fade-right" class="col-md-6 col-sm-6 mb-4">
                                            <div class="card">

                                                <div class="card-body d-flex pt-4 pb-4">
                                                    <div class="mr-2">
                                                        <i class="eva eva-pie-chart-outline mr-1 text-36"></i>
                                                    </div>
                                                    <div class="">
                                                        <h4 class="card-title font-weight-bold text-left">MAP & BRAINSTORM
                                                        </h4>
                                                       
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end of col  --> */}
                                        <div data-aos="fade-left" class="col-md-6 col-sm-6 mb-4">
                                            <div class="card">

                                                <div class="card-body d-flex pt-4 pb-4">
                                                    <div class="mr-2"><i class="eva eva-clock-outline mr-1 text-36"></i>
                                                    </div>
                                                    <div class="">
                                                        <h4 class="card-title font-weight-bold text-left"> PLANNING & SOURCING</h4>
                                                        {/* <p class="m-0">Contrary to popular belief, Lorem Ipsum is not
                                                            simply
                                                            random text.</p> */}
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end of col  --> */}
                                        <div data-aos="fade-up" class="col-md-6 col-sm-6 mb-4 mb-lg-0 mb-md-0 mb-sm-0">
                                            <div class="card">

                                                <div class="card-body d-flex pt-4 pb-4">
                                                    <div class="mr-2"><i class="eva eva-lock-outline mr-1 text-36"></i>
                                                    </div>
                                                    <div class="">
                                                        <h4 class="card-title font-weight-bold text-left">CHECKS &
BALANCES</h4>
                                                       
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end of col  --> */}
                                        <div data-aos="fade-up" class="col-md-6 col-sm-6 mb-0 mb-lg-0 mb-md-0 mb-sm-0">
                                            <div class="card">

                                                <div class="card-body d-flex pt-4 pb-4">
                                                    <div class="mr-2"><i class="eva eva-pricetags-outline mr-1 text-36"></i>
                                                    </div>
                                                    <div class="">
                                                        <h4 class="card-title font-weight-bold text-left">TRANSITION &
ONBOARDING
                                                        </h4>
                                                       
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end of col  --> */}

                                    </div>
                                </div>
                                {/* <!-- end of col6 row --> */}
                                <div class="col-md-12 col-lg-5 col-sm-12 offset-lg-1">

                                    {/* <div class="right-image-carousel"> */}

                                    <Swiper
					spaceBetween={30} centeredSlides={true} loop={true} autoplay={{
						"delay": 3000,
						"disableOnInteraction": false
					  }} className="mySwiper"
					>
					<SwiperSlide>
                                        <div>
                                            <img alt="Card image cap" class="img-responsive w-100 mb-4" src="/assets/images/homemap.png" />
                                        </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                        <div><img alt="Card image cap" class="img-responsive w-100 mb-4" src="/assets/images/homeplan.png" /></div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                        <div>
                                            <img alt="Card image cap" class="img-responsive w-100 mb-4" src="/assets/images/homebalance.webp" />
                                        </div>
                                        </SwiperSlide>
                                        </Swiper>
                                    {/* </div> */}
                                    {/* <div class="slick_control_wrap text-center">


                                        <button type="button" class="ngu_control_button half-button right-image-arrow-left leftRs btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></button>
                                        <button type="button" class="ngu_control_button half-button right-image-arrow-right rightRs btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </section>

                    {/* <!-- end::right-img --> */}

                    {/* <!-- start::price-1 --> */}
                    <section id="pricing-wrap" class="pricing-one-wrap p-t-b-80">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 section-header mb-5">
                                    <h2 class="font-weight-bold">Our Employment Services</h2>
                                    <p>Our aim is to connect employers with prospective employees and facilitate the opportunity to onboarding journey.</p>
                                </div>
                                <div data-aos="fade-right" class="col-sm-6 col-md-6 col-lg-4 mb-lg-0 mb-4 pricing-single-wrap">
                                    <div class="card o-hidden">
                                        <div class="card-header text-center p-3 mb-2 text-white serv-card" >
                                            <h3 class="card-title font-weight-bold mb-2 text-uppercase  text-white">Search</h3>
                                            <p class="text-uppercase mb-0 text-center">CONSULTATION</p>
                                        </div>
                                        <div class="card-body pt-0 text-center">

                                           
                                            <div class="offer-lists mb-4">

                                                <ul class="list-group list-group-flush" style={{marginTop  : '20px'}}>

                                                    <li class="list-group-item p-1">Understand job requirement</li>
                                                    <li class="list-group-item p-1">Search employer</li>
                                                    <li class="list-group-item p-1">Iterate talent pool
                                                    </li>
                                                    <li class="list-group-item p-1">Advice relevant opportunity
                                                    </li>


                                                </ul>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end of card --> */}
                                <div data-aos="fade-up" class="col-sm-6 col-md-6 col-lg-4 mb-lg-0 mb-4 pricing-single-wrap">
                                    <div class="card o-hidden">
                                        <div class="card-header text-center p-3 mb-2 text-white serv-card">
                                            <h3 class="card-title text-white font-weight-bold mb-2 text-uppercase">
                                            Bridge
                                            </h3>
                                            <p class="text-uppercase mb-0 text-center">hire right</p>
                                        </div>
                                        <div class="card-body pt-0 text-center">

                                           
                                            <div class="offer-lists mb-4">

                                                <ul class="list-group list-group-flush" style={{marginTop  : '20px'}}>
                                                    <li class="list-group-item p-1">Choose the right job</li>
                                                    <li class="list-group-item p-1">Navigate resource pool</li>
                                                    <li class="list-group-item p-1">Hire on behalf of client</li>
                                                    
                                                    <li class="list-group-item p-1">Channelise communication</li>
                                                 </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end of card --> */}
                                <div data-aos="fade-up" class="col-sm-6 col-md-6 col-lg-4 mb-lg-0 mb-md-0 mb-sm-0 mb-4 pricing-single-wrap">
                                    <div class="card o-hidden">
                                        <div class="card-header text-center p-3 mb-2 text-white serv-card">
                                            <h3 class="card-title font-weight-bold mb-2 text-uppercase text-white">Join</h3>
                                            <p class="text-uppercase mb-0 text-center">transition</p>
                                        </div>
                                        <div class="card-body pt-0 text-center">

                                          
                                            <div class="offer-lists mb-4">

                                                <ul class="list-group list-group-flush" style={{marginTop  : '20px'}}>
                                                    <li class="list-group-item p-1">Onboard candidate</li>
                                                    <li class="list-group-item p-1">End to end transition</li>
                                                    <li class="list-group-item p-1">Handle legal formalities</li>
                                                    <li class="list-group-item p-1">Settle them hassle free</li>

                                                          </ul>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                          


                            </div>
                        </div>
                    </section>
                    {/* <!-- end::price-1 --> */}

                    {/* <!-- start::team --> */}
                    <section id="teams-wrap" class="team-wrap p-t-b-80 light-gray">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 section-header mb-5">
                                    <h2 class="font-weight-bold">Meet Our Team</h2>
                                    <p>Industry experts with more than two decades of experience. Their only aim to make it easy for you!</p>

                                </div>
                            </div>
                            <div class="row">
                            <div data-aos="fade-up" class="col-md-6 col-lg-4 col-sm-6 mb-lg-0 mb-md-0 mb-sm-0 mb-4">
                                    <div class="card card-profile-1">
                                        <div class="card-body text-center">
                                            <div class="avatar box-shadow-2 mb-3">
                                                <img src="/assets/images/landing/faces/ArunSharma.png" alt="" />
                                            </div>
                                            <h5 class="font-weight-medium card-title m-0">Brig Arun Sharma</h5>
                                            <p class="mt-0 text-center">President</p>
                                            <p>55 yrs of leaderships experience in Telecommunications and Defence with exceptional track record. His qualification included MBA, BTech, PGDPM and EDP credentials. He has been an outstanding sportsman. Winner of Wills Open Golf Tournament in 1984 & ever since have won many golf tournaments while serving and post retirement.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-right" class="col-md-6 col-lg-4 col-sm-6 mb-lg-0 mb-4">
                                    <div class="card card-profile-1">
                                        <div class="card-body text-center">
                                            <div class="avatar box-shadow-2 mb-3">
                                                <img src="/assets/images/landing/faces/AnilSharma.png" alt="" />
                                            </div>
                                            <h5 class="font-weight-medium card-title m-0">Mr. Anil Sharma</h5>
                                            <p class="mt-0 text-center">Vice President -Marketing</p>
                                            <p>Anil Sharma have been working in the trade since 1998. He right from the beginning helped, assisted overseas clients during their visit to Asian countries. He speaks Spanish, French & travelled for business to various countries in Asia, Europe & Latin America. His vast experience of travel plays an important role to choose, collaborate & decide countries, places for our clients. </p>
                                          
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end of col --> */}
                                <div data-aos="fade-up" class="col-md-6 col-lg-4 col-sm-6 mb-lg-0 mb-4">
                                    <div class="card card-profile-1">
                                        <div class="card-body text-center">
                                            <div class="avatar box-shadow-2 mb-3">
                                                <img src="/assets/images/landing/faces/SunilSharma.png" alt="" />
                                            </div>
                                            <h5 class="font-weight-medium card-title m-0">Mr. Sunil Kumar</h5>
                                            <p class="mt-0 text-center">Vice President-Legal</p>
                                            <p>Over more than 20 years working in several esteemed diplomatic missions, Sunil has undertaken several assignments & accomplished with his analytical, creative & interpersonal skills.

Detail oriented experience of Latin America & Europe, he is well-versed in dealing with immigration issues, processing visas, documentation & well equiped with immigration law.
</p>
                                           
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end of col --> */}
                              
                                {/* <!-- end of col --> */}

                                
                            </div>
                        </div>
                    </section>
                    {/* <!-- end::team --> */}


        

                    {/* <!-- start::contact-form --> */}
                    <section id="contacts-wrap" class="contact-form-wrap light-gray p-t-b-80">
                        <div class="container">
                            <div class="row">



                                <div data-aos="fade-right" class="form-wrap col-md-6 mb-4">
                                    <div class="section-header mb-5">
                                        <h2 class="font-weight-bold">Contact Us!</h2>
                                        <p>  We are here to help you in every possible way to make your dream feel easy. If you have anything in mind, kindly send us a message anytime. We will contact back soon.
                                    <br/>Have a nice day!</p>

                                    </div>
                                    {/* <div *ngif="success" class="alert alert-success alert-dismissible fade show" role="alert" 
                                    [@animate]="{value:'*',params:{y:'-120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
                                        <button type="button" class="close" onclick="success=false">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <strong>Your Message Sent succefully</strong>
                                    </div> */}

<form class="row form" role="form" onSubmit={(e)=>{
                                        e.preventDefault()
                                    }}>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-person-outline"></i>
                                            </label>
                                            <input type="text" name="Name" id="" class="form-control" placeholder="Your Name" required
                                            value={name}
                                            onChange={(text)=>{
                                                setName(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-email-outline"></i>
                                            </label>
                                            <input type="email" name="email" class="form-control" placeholder="Your email" aria-describedby="helpId" required
                                            value={email}
                                            onChange={(text)=>{
                                                setEmail(text.target.value)
                                            }}
                                            />

                                        </div>
                                       

                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-phone-outline"></i>
                                            </label>
                                            <input type="mobile" required name="mobile" class="form-control" placeholder="Your mobile" aria-describedby="helpId" 
                                            value={mobile}
                                            onChange={(text)=>{
                                                setMobile(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-edit-2-outline"></i>
                                            </label>
                                            {/* <input type="text" required name="subject" class="form-control" placeholder="Your subject here" aria-describedby="helpId" /> */}
                                            <div class="dropdown">
                                                        <button style={{textAlign: 'left', paddingTop : '15px', paddingBottom : '30px'}} class="dropdown-toggle form-control" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                           <div style={{color : subject == '' ? '#bbb' : '#47404f'}}>
                                                           {subject == '' ? 'Your subject here' : subject}
                                                           </div>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item"
                                            onClick={()=>{
                                                setSubject('Study Abroad')
                                            }}>Study Abroad</a>
                                                            <a class="dropdown-item" onClick={()=>{
                                                setSubject('Work Abroad')
                                            }}>Work Abroad</a>
                                                            <a class="dropdown-item" onClick={()=>{
                                                setSubject('Settle Abroad')
                                            }}>Settle Abroad</a>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div class="form-group mb-4 col-md-12">

                                            <label for="">
                                                <i class="eva eva-edit-outline"></i>
                                            </label>
                                            <textarea class="form-control" placeholder="Your message here" name="message" rows="5"
                                            value={message}
                                            onChange={(text)=>{
                                                setMessage(text.target.value)
                                            }}
                                            ></textarea>


                                        </div>

                                        <div class="form-group col-md-12">
                                            <button type="submit"
                                            disabled={buttonLocked}
                                            onClick={()=>{
                                                sendMailer()
                                            }}
                                            class="btn btn-block half-button form-submit-button btn-large btn-gradient">
                                                {buttonLocked ? 'Sending...' : 'Send Message'}
                                                
                                            </button>
                                            <p style={{color : 'red'}}>{errMsg}</p>
                                        </div>



                                    </form>
                                    <div style={{textAlign : 'center', display : sendSuccess ? '' : 'none'}}>
					<p style={{color : 'green', textAlign : 'center'}}><CheckSquare  size={15}/>&nbsp;
					Thank you for your message! We will get back to you soon.</p>
					</div>
                             
                                </div>
                                <div class="col-md-6 align-items-center">
                                    <div class="right-contact-wrap ml-5">

                                        <img data-aos="fade-left" src="/assets/images/contacthome.png" class="img-responsive zoom-fade" alt="Image" />

                                        {/* <!-- <div class="address-wrap mb-4">--> */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}

                </div>
                {/* <!-- ============ Main content End ============= --> */}




            </div>
        </div>
    </div>
  );
}

export default Home;
