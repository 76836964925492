import { BookOpen, Briefcase, DollarSign, Dribbble, Facebook, Globe, Instagram, Mail, MapPin, Menu, Phone, Share2, ThumbsUp, Twitch, Twitter } from 'react-feather'
import moment from 'moment'
import {Helmet} from "react-helmet";

const asia = [{name: 'Thailand', flag : '/assets/images/flags/th.png'},
{name: 'Cambodia', flag : '/assets/images/flags/kh.png'},
{name: 'Vietnam', flag : '/assets/images/flags/vn.png'},
{name: 'Singapore', flag : '/assets/images/flags/sg.png'},
{name: 'Sri - Lanka', flag : '/assets/images/flags/lk.png'},
{name: 'Malaysia', flag : '/assets/images/flags/my.png'}]

const middle_east = [{name: 'Bahrain', flag : '/assets/images/flags/bh.png'},
{name: 'Dubai', flag : '/assets/images/flags/ae.png'},
{name: 'Oman', flag : '/assets/images/flags/om.png'},
{name: 'Qatar', flag : '/assets/images/flags/qa.png'},
{name: 'Kuwait', flag : '/assets/images/flags/kw.png'},
{name: 'Turkey', flag : '/assets/images/flags/tr.png'}]

const australia = [{name: 'Australia', flag : '/assets/images/flags/au.png'},
{name: 'New Zealand', flag : '/assets/images/flags/nz.png'}]

const europe = [{name: 'Portugal', flag : '/assets/images/flags/pt.png'},
{name: 'Germany', flag : '/assets/images/flags/de.png'},
{name: 'France', flag : '/assets/images/flags/fr.png'},
{name: 'Denmark', flag : '/assets/images/flags/dk.png'},
{name: 'Finland', flag : '/assets/images/flags/fi.png'},
{name: 'Ireland', flag : '/assets/images/flags/ie.png'},
{name: 'Belgium', flag : '/assets/images/flags/be.png'},
{name: 'Poland', flag : '/assets/images/flags/pl.png'},
{name: 'Spain', flag : '/assets/images/flags/es.png'},
{name: 'Italy', flag : '/assets/images/flags/it.png'},
{name: 'United Kingdom', flag : '/assets/images/flags/gb.png'}]

const lamerica = [{name: 'Brazil', flag : '/assets/images/flags/br.png'},
{name: 'Chile', flag : '/assets/images/flags/cl.png'},
{name: 'Argentina', flag : '/assets/images/flags/ar.png'},
{name: 'Uruguay', flag : '/assets/images/flags/uy.png'}]

const namerica = [{name: 'U.S.A.', flag : '/assets/images/flags/us.png'},
{name: 'Canada', flag : '/assets/images/flags/ca.png'},
{name: 'Mexico', flag : '/assets/images/flags/mx.png'},
{name: 'Belize', flag : '/assets/images/flags/bz.png'}]

function TopCountries() {
  return (
    <div className="App">

          <Helmet>
        <title>International Education Consultants | Visa Overseas Consultant</title>
        <meta name="description" content="If You want Best International Education consultants, we offer career counselling, career planning advice, Contact Us (+91) 981 081 7884" />
        <meta keywords="Best International Education consultants, Visa Overseas Consultant" />
    </Helmet>
    
    <div class="app-landing-wrap landing_wrap ">
            <div id="landing_wrap" class="landing-gradient-red-orange">

            
                {/* <!-- ============ Main content start ============= --> */}
                <div class="main-content-wrap">

                    {/* <!-- intro-section --> */}

                    <section id="intro-wrap" class="intro1Wrap1 text-left text-white">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 intro1LeftSection pb-5" style={{margin : 'auto'}}>
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">TOP COUNTRIES</h1>
                                  

                                </div>
                                <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="/assets/images/worldflags.png" class="img-responsive" alt="" style={{paddingTop : '10px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay">

                        </div>

                    </section>
                    {/* <!-- end::intro-section --> */}

                    <section id="contacts-wrap" class="contact-form-wrap" style={{paddingBottom : '0px', paddingTop : '50px'}}>
                        <div class="container">
                            <div class="row">



                                <div data-aos="fade-right" class="form-wrap col-md-12 mb-4">
                                    <div class="section-header mb-5">
                                        <h2 class="font-weight-bold" style={{marginTop : '0px'}}>Our Global Presence</h2>
                                        <p>  Our global presence will make it easy for you to choose the country of your choice. Our experts will take care of your mirgration from every possible direction.</p>

                                    </div>
                                  


                                </div>
                              
                            </div>
                        </div>
                    </section>

                    <section id="contacts-wrap" class="contact-form-wrap light-gray" style={{paddingTop : '30px'}}>
                        <div class="container">
                        <div class="row country-row">


<div class="col-md-12 section-header mb-5 text-left">
    <h2 class="font-weight-bold text-center">Asia</h2>
   

</div>


<div class="col-md-12 col-lg-12 col-sm-12 mb-4 mb-lg-0 text-center">
   
                                    <div data-aos="fade-up" class="slick-work-carousel-wrap1">

                                    {/* <div class="col-md-6 col-lg-6 col-sm-6 mb-2"> */}
           {asia.map((dt, index)=>(
            <div class="card text-left o-hidden mb-2">

<div class="card-body pt-4 pb-4 text-center">
    {/* <i class="eva eva-file-text-outline text-30"></i> */}
    <img src={dt.flag} class='flag-img'  style={{display : 'initial'}}/>
    <h4 class="card-title pt-2 text-16 " style={{marginBottom : '0px'}}>{dt.name}</h4>

</div>
</div>
           ))} 
        {/* </div> */}
                                    </div>
                                    {/* <div class="slick_control_wrap text-center">

                                    <span type="button" class="ngu_control_button half-button leftRs work-arrow-left btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></span>
                                    <button type="button" class="ngu_control_button half-button rightRs work-arrow-right btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                </div> */}
                                </div>


</div>



<div class="row mt-5 country-row">


<div class="col-md-12 section-header mb-5 text-left">
    <h2 class="font-weight-bold  text-center">Middle East</h2>
    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat ea quam
        laborum, ducimus, laboriosam sint dolorum aspernatur libero.</p> */}

</div>


<div class="col-md-12 col-lg-12 col-sm-12 mb-4 mb-lg-0 text-center">
   
                                    <div data-aos="fade-up" class="slick-work-carousel-wrap1">

                                    {/* <div class="col-md-6 col-lg-6 col-sm-6 mb-2"> */}
           {middle_east.map((dt, index)=>(
            <div class="card text-left o-hidden mb-2">

<div class="card-body pt-4 pb-4 text-center">
    {/* <i class="eva eva-file-text-outline text-30"></i> */}
    <img src={dt.flag} class='flag-img' style={{display : 'initial'}}/>
    <h4 class="card-title pt-2 text-16" style={{marginBottom : '0px'}}>{dt.name}</h4>

</div>
</div>
           ))} 
        {/* </div> */}
                                    </div>
                                    {/* <div class="slick_control_wrap text-center">

                                    <span type="button" class="ngu_control_button half-button leftRs work-arrow-left btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></span>
                                    <button type="button" class="ngu_control_button half-button rightRs work-arrow-right btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                </div> */}
</div>

</div>

<div class="row mt-5 country-row">


<div class="col-md-12 section-header mb-5 text-left">
    <h2 class="font-weight-bold  text-center">Australia & Oceania</h2>
   

</div>


<div class="col-md-12 col-lg-12 col-sm-12 mb-4 mb-lg-0 text-center">
   
                                    <div data-aos="fade-up" class="slick-work-carousel-wrap1">

                                    {/* <div class="col-md-6 col-lg-6 col-sm-6 mb-2"> */}
           {australia.map((dt, index)=>(
            <div class="card text-left o-hidden mb-2">

<div class="card-body pt-4 pb-4 text-center">
    {/* <i class="eva eva-file-text-outline text-30"></i> */}
    <img src={dt.flag} class='flag-img' style={{display : 'initial'}}/>
    <h4 class="card-title pt-2 text-16" style={{marginBottom : '0px'}}>{dt.name}</h4>

</div>
</div>
           ))} 
        {/* </div> */}
                                    </div>
                                    {/* <div class="slick_control_wrap text-center">

                                    <span type="button" class="ngu_control_button half-button leftRs work-arrow-left btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></span>
                                    <button type="button" class="ngu_control_button half-button rightRs work-arrow-right btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                </div> */}
</div>

</div>

<div class="row mt-5 country-row">


<div class="col-md-12 section-header mb-5 text-left">
    <h2 class="font-weight-bold text-center">Europe</h2>
   

</div>


<div class="col-md-12 col-lg-12 col-sm-12 mb-4 mb-lg-0 text-center">
   
                                    <div data-aos="fade-up" class="slick-work-carousel-wrap1">

                                    {/* <div class="col-md-6 col-lg-6 col-sm-6 mb-2"> */}
           {europe.map((dt, index)=>(
            <div class="card text-left o-hidden mb-2">

<div class="card-body pt-4 pb-4 text-center">
    {/* <i class="eva eva-file-text-outline text-30"></i> */}
    <img src={dt.flag} class='flag-img' style={{display : 'initial'}}/>
    <h4 class="card-title pt-2 text-16" style={{marginBottom : '0px'}}>{dt.name}</h4>

</div>
</div>
           ))} 
        {/* </div> */}
                                    </div>
                                    <div class="slick_control_wrap text-center">

                                    <span type="button" class="ngu_control_button half-button leftRs work-arrow-left btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></span>
                                    <button type="button" class="ngu_control_button half-button rightRs work-arrow-right btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                </div>
</div>

</div>


<div class="row mt-5 country-row">


<div class="col-md-12 section-header mb-5 text-left">
    <h2 class="font-weight-bold text-center">Latin America</h2>
   

</div>


<div class="col-md-12 col-lg-12 col-sm-12 mb-4 mb-lg-0 text-center">
   
                                    <div data-aos="fade-up" class="slick-work2">

                                   
           {lamerica.map((dt, index)=>(
            <div class="card text-left mb-2">

<div class="card-body pt-4 pb-4 text-center">
    {/* <i class="eva eva-file-text-outline text-30"></i> */}
    <img src={dt.flag} class='flag-img' style={{display : 'initial'}}/>
    <h4 class="card-title pt-2 text-16" style={{marginBottom : '0px'}}>{dt.name}</h4>

</div>
</div>
           ))} 

                                    </div>
                                    {/* <div class="slick_control_wrap text-center">

                                    <span type="button" class="ngu_control_button half-button leftRs work-arrow-left btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></span>
                                    <button type="button" class="ngu_control_button half-button rightRs work-arrow-right btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                </div> */}
</div>

</div>



<div class="row mt-5 ">


<div class="col-md-12 section-header mb-5 text-left">
    <h2 class="font-weight-bold text-center">North America</h2>
   

</div>


<div class="col-md-12 col-lg-12 col-sm-12 mb-4 mb-lg-0 text-center">
   
                                    <div data-aos="fade-up" class="slick-work2">

                                   
           {namerica.map((dt, index)=>(
            <div class="card text-left mb-2">

<div class="card-body pt-4 pb-4 text-center">
    {/* <i class="eva eva-file-text-outline text-30"></i> */}
    <img src={dt.flag} class='flag-img' style={{display : 'initial'}}/>
    <h4 class="card-title pt-2 text-16" style={{marginBottom : '0px'}}>{dt.name}</h4>

</div>
</div>
           ))} 

                                    </div>
                                    {/* <div class="slick_control_wrap text-center">

                                    <span type="button" class="ngu_control_button half-button leftRs work-arrow-left btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></span>
                                    <button type="button" class="ngu_control_button half-button rightRs work-arrow-right btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                </div> */}
</div>

</div>





                        </div>
                    </section>

                    

                </div>
                {/* <!-- ============ Main content End ============= --> */}




            </div>
        </div>
    </div>
  );
}

export default TopCountries;
