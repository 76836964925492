import { useEffect, useState } from 'react'; 
import { BookOpen, Briefcase, CheckSquare, DollarSign, Dribbble, Facebook, Globe, Instagram, Mail, MapPin, Menu, Phone, Share2, ThumbsUp, Twitch, Twitter } from 'react-feather'
import moment from 'moment'
import PostApiCall from './Api/Api'
import {Helmet} from "react-helmet";

function ContactUs() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [buttonLocked, setButtonLocked] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')




    const sendMailer = () => {
								
											setErrMsg('')
											if(name != '' && email != '' && mobile != '' && subject != '' && message != ''){
												setButtonLocked(true)
												PostApiCall.postRequest({
													name, 
													email,
													mobile,
													subject,
                                                    message
												  }, "QueryMailer").then((results) => results.json().then(obj1 => {
											  
													if (results.status === 200 || results.status === 201) {
														
                                                        setName('')
                                                        setEmail('')
                                                        setMobile('')
                                                        setSubject('')
                                                        setMessage('')
                                                        setSendSuccess(true)
                                                        setButtonLocked(false)
                                                        
													}else {
                                                        setButtonLocked(false)
                                                        setErrMsg('Something wnet wrong, try again later.')
														
													}
												}))


											}
									
    }

  return (
    <div className="App">

          <Helmet>
        <title>Contact Us - ShiftAbroad</title>
        <meta name="description" content="We are here to help you in every possible way to make your dream feel easy. If you have anything in mind, kindly send us a message anytime at (+91) 997 118 5324" />
    </Helmet>
    

    <div class="app-landing-wrap landing_wrap">
            <div id="landing_wrap" class="landing-gradient-red-orange">

            
                {/* <!-- ============ Main content start ============= --> */}
                <div class="main-content-wrap">

                    {/* <!-- intro-section --> */}

                    <section id="intro-wrap" class="intro1Wrap1 text-left text-white">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 intro1LeftSection pb-5" style={{margin : 'auto'}}>
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">CONTACT US</h1>
                                 

                                </div>
                                <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="/assets/images/contactus.png" class="img-responsive" alt="" style={{paddingTop : '20px', paddingBottom : '20px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay">

                        </div>

                    </section>
                    {/* <!-- end::intro-section --> */}

 {/* <!-- start::contact-form --> */}
 <section id="contacts-wrap" class="contact-form-wrap" style={{paddingBottom : '0px', paddingTop : '50px'}}>
                        <div class="container">
                            <div class="row">



                                <div data-aos="fade-right" class="form-wrap col-md-12 mb-4">
                                    <div class="section-header mb-5">
                                        <h2 class="font-weight-bold">Get in touch with us !</h2>
                                        <p>  We are here to help you in every possible way to make your dream feel easy. If you have anything in mind, kindly send us a message anytime. We will contact back soon.
                                    <br/>Have a nice day!</p>

                                    </div>
                                  


                                </div>
                              
                            </div>
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}
 {/* <!-- start::contact-form --> */}
 <section id="contacts-wrap" class="contact-form-wrap light-gray p-t-b-80" style={{ paddingTop: '80px',
    paddingBottom: '150px'}}>
                        <div class="container">
                            <div class="row">



                                <div data-aos="fade-right" class="form-wrap col-md-6 mb-4">
                               
                                    {/* <div *ngif="success" class="alert alert-success alert-dismissible fade show" role="alert" 
                                    [@animate]="{value:'*',params:{y:'-120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
                                        <button type="button" class="close" onclick="success=false">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <strong>Your Message Sent succefully</strong>
                                    </div> */}


                                    <form class="row form" role="form" onSubmit={(e)=>{
                                        e.preventDefault()
                                    }}>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-person-outline"></i>
                                            </label>
                                            <input type="text" name="Name" id="" class="form-control" placeholder="Your Name" required
                                            value={name}
                                            onChange={(text)=>{
                                                setName(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-email-outline"></i>
                                            </label>
                                            <input type="email" name="email" class="form-control" placeholder="Your email" aria-describedby="helpId" required
                                            value={email}
                                            onChange={(text)=>{
                                                setEmail(text.target.value)
                                            }}
                                            />

                                        </div>
                                       

                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-phone-outline"></i>
                                            </label>
                                            <input type="mobile" required name="mobile" class="form-control" placeholder="Your mobile" aria-describedby="helpId" 
                                            value={mobile}
                                            onChange={(text)=>{
                                                setMobile(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-edit-2-outline"></i>
                                            </label>
                                            {/* <input type="text" required name="subject" class="form-control" placeholder="Your subject here" aria-describedby="helpId" /> */}
                                            <div class="dropdown">
                                                        <button style={{textAlign: 'left', paddingTop : '15px', paddingBottom : '30px'}} class="dropdown-toggle form-control" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                           <div style={{color : subject == '' ? '#bbb' : '#47404f'}}>
                                                           {subject == '' ? 'Your subject here' : subject}
                                                           </div>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item"
                                            onClick={()=>{
                                                setSubject('Study Abroad')
                                            }}>Study Abroad</a>
                                                            <a class="dropdown-item" onClick={()=>{
                                                setSubject('Work Abroad')
                                            }}>Work Abroad</a>
                                                            <a class="dropdown-item" onClick={()=>{
                                                setSubject('Settle Abroad')
                                            }}>Settle Abroad</a>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div class="form-group mb-4 col-md-12">

                                            <label for="">
                                                <i class="eva eva-edit-outline"></i>
                                            </label>
                                            <textarea class="form-control" placeholder="Your message here" name="message" rows="5"
                                            value={message}
                                            onChange={(text)=>{
                                                setMessage(text.target.value)
                                            }}
                                            ></textarea>


                                        </div>

                                        <div class="form-group col-md-12">
                                            <button type="submit"
                                            disabled={buttonLocked}
                                            onClick={()=>{
                                                sendMailer()
                                            }}
                                            class="btn btn-block half-button form-submit-button btn-large btn-gradient">
                                                {buttonLocked ? 'Sending...' : 'Send Message'}
                                                
                                            </button>
                                            <p style={{color : 'red'}}>{errMsg}</p>
                                        </div>



                                    </form>
                                    <div style={{textAlign : 'center', display : sendSuccess ? '' : 'none'}}>
					<p style={{color : 'green', textAlign : 'center'}}><CheckSquare  size={15}/>&nbsp;
					Thank you for your message! We will get back to you soon.</p>
					</div>
                                    

                                </div>
                                <div data-aos="fade-right" class="form-wrap col-md-1 mb-4"></div>
                                <div data-aos="fade-right" class="form-wrap col-md-5 mb-4">
                                {/* <div class="col-lg-6 offset-lg-1"> */}
                                        {/* <h2 class="font-weight-bold">Some attractive benefits provide</h2>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident eveniet
                                            illo,
                                            placeat dolorum delectus nam error itaque ratione facilis quod praesentium.
                                        </p> */}

                                        <div class="row">
                                            <div class="col-md-12 mb-4" style={{    
                                                borderBottom: '1px solid #ececec',
                                                    paddingBottom: '30px'}}>
                                                <div class="benefits-contents d-flex">
                                                    <i class="eva eva-phone-outline mr-2 text-28"></i>
                                                    <div class="benefits-contents__info">
                                                        <h3 class="card-title font-weight-bold">Call Us</h3>
                                                        <span class="text-15">
                                                            <b>Mob : </b><a href='Tel:+919971185324'>(+91) 997 118 5324</a><br/>
                                                            <b>Mob : </b><a href='Tel:+919810817884'>(+91) 981 081 7884</a><br/>
                                                            <b>Mob : </b><a href='Tel:+919818444514'>(+91) 981 844 4514</a><br/>
9818444514
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4" style={{    
                                                borderBottom: '1px solid #ececec',
                                                    paddingBottom: '30px'}}>
                                                <div class="benefits-contents d-flex">
                                                    <i class="eva eva-email-outline mr-2 text-28"></i>
                                                    <div class="benefits-contents__info">
                                                        <h3 class="card-title font-weight-bold">E-mail</h3>
                                                        <span class="text-15"><a href="mailto:shift@shiftabroad.in">shift@shiftabroad.in</a>
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4" >
                                                <div class="benefits-contents d-flex">
                                                    <i class="eva eva-home-outline mr-2 text-28"></i>
                                                    <div class="benefits-contents__info">
                                                        <h3 class="card-title font-weight-bold">Our Address</h3>
                                                        <span class="text-15">
                                                        E 85,2nd Floor, Sector 11,<br/>
                                                        Faridabad-121006, India.<br/>
                                                        


                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                    {/* </div> */}
                                    </div>


                                    {/* <div data-aos="fade-right" class="form-wrap col-md-12 mb-4">
                                    <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=Shiftabroad,%20Faridabad%20121006&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                                        </div> */}
                              
                            </div>
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}
                </div>
                {/* <!-- ============ Main content End ============= --> */}




            </div>
        </div>
    </div>
  );
}

export default ContactUs;
