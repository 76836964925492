import { useEffect, useState } from 'react'; 
import { BookOpen, Briefcase, CheckSquare, CreditCard, DollarSign, Dribbble, Facebook, FileText, Globe, Instagram, Mail, MapPin, Menu, Phone, Share2, ThumbsUp, Twitch, Twitter, Users } from 'react-feather'
import moment from 'moment'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import PostApiCall from './Api/Api'
import {Helmet} from "react-helmet";

const middle_east = [{name: 'Massachusetts Institute of Technology, USA', flag : ''},
{name: 'Stanford University,USA', flag : ''},
{name: 'Harvard University,USA', flag : ''},
{name: 'Caltech California,USA', flag : ''},
{name: 'University of Oxford,UK', flag : ''},
{name: 'ETH Zurich, Switzerland', flag : ''},
{name: 'University of Cambridge, UK', flag : ''},
{name: 'Imperial college London, UK', flag : ''},
{name: 'The university of Chicago, USA', flag : ''},
{name: 'University college London,UK', flag : ''}
]

function StudyAbroad() {
    // console.log(window.screen.availWidth)


      
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [subject, setSubject] = useState('Study Abroad')
    const [message, setMessage] = useState('')
    const [buttonLocked, setButtonLocked] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')




    const sendMailer = () => {
								
											setErrMsg('')
											if(name != '' && email != '' && mobile != '' && subject != '' && message != ''){
												setButtonLocked(true)
												PostApiCall.postRequest({
													name, 
													email,
													mobile,
													subject,
                                                    message
												  }, "QueryMailer").then((results) => results.json().then(obj1 => {
											  
													if (results.status === 200 || results.status === 201) {
														
                                                        setName('')
                                                        setEmail('')
                                                        setMobile('')
                                                        setSubject('')
                                                        setMessage('')
                                                        setSendSuccess(true)
                                                        setButtonLocked(false)
                                                        
													}else {
                                                        setButtonLocked(false)
                                                        setErrMsg('Something wnet wrong, try again later.')
														
													}
												}))


											}
									
    }



  return (
    <div className="App">

<Helmet>
        <title>Best International Study Visa Abroad Consultants in Delhi - ShiftAbroad</title>
        <meta name="description" content="We are the one-stop solution for all your international study needs and have been India's Leading Overseas Education Consultancy Agency in Faridabad. Visit Now." />
        <meta keywords="Best in study Abroad Agencies, Abroad Study Visa Consultants, Best International Education consultants, best study Abroad Agencies, Study Overseas Consultants in Delhi" />
    </Helmet>

    
    <div class="app-landing-wrap landing_wrap">
            <div id="landing_wrap" class="landing-gradient-red-orange">

            
                {/* <!-- ============ Main content start ============= --> */}
                <div class="main-content-wrap">

                    {/* <!-- intro-section --> */}

                    <section id="intro-wrap" class="intro1Wrap1 text-left text-white">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 intro1LeftSection pb-5" style={{margin : 'auto'}}>
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">STUDY ABROAD</h1>
                                   
                                   
                                    <div class="intro1Buttons" style={{margin : 'auto'}}>
                                       
                                        <a id="" href="studyabroad/#contact" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                            Contact us now
                                        </a>

                                    </div>

                                </div>
                                <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="/assets/images/studybanner.png" class="img-responsive" alt="" style={{paddingTop : '20px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay">

                        </div>

                    </section>
                    {/* <!-- end::intro-section --> */}

 {/* <!-- start::contact-form --> */}
 <section id="contacts-wrap" class="contact-form-wrap" style={{paddingBottom : '0px',paddingTop : '50px'}}>
                        <div class="container">
                            <div class="row">



                                <div data-aos="fade-right" class="form-wrap col-md-12 mb-4">
                                    <div class="section-header mb-5">
                                        <h2 class="font-weight-bold" style={{marginTop : '0px'}}>Get your dream university with us!</h2>
                                        <p> ShiftAbroad will help you to find various universities worldwide & get you to the 
city of your choice. We are pioneers in providing one stop solution for your all-global study requirements.  
Our association with many reputed institutions worldwide would be guiding & assisting 
you in all the processes. 
</p>

                                    </div>
                                  


                                </div>
                              
                            </div>
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}
 {/* <!-- start::contact-form --> */}
 <section id="" class="right-image-wrap light-gray p-t-b-80" style={{ paddingTop: '80px',
    paddingBottom: '0px'}}>
                        <div class="container">


                        <div class="row text-center">
                                    <div class="col-md-12 mb-5">
                                        <h2 class="font-weight-bold">Easy & Simple Process</h2>
                                        <p class="text-center">Our user friendly & easy to understand process will help you achieve your goals with ease without any hassle</p>

                                    </div>

                                    <div class="col-md-6 col-lg-4 p-5 mb-2">
                                    <Globe size={30} class="icon-color-layer" />
                                        <h3 class="font-weight-bold mb-2 mt-2">Research well</h3>
                                        <p class="card-text">Investigate courses, universities or preferred locations as per your interest, narrow down a few options on the basis of opportunities & your budget.</p>
                                    </div>
                                    <div class="col-md-6 col-lg-4 p-5 mb-2">
                                    <Users size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Speak with an Expert</h3>
                                        <p class="card-text">Make an appointment with us, our expert team will go through all the big & small details to ensure the best possible fit for you.</p>
                                    </div>
                                    <div class="col-md-6 col-lg-4 p-5 mb-2">
                                    <FileText size={30} class="icon-color-layer" />
                                        
                                        <h3 class="font-weight-bold mb-2 mt-2">Make your Application</h3>
                                        <p class="card-text">It's time for you to apply, your counsellor will help you create a sound application & contact your dream university in order to sooth selection process.</p>
                                    </div>
                                    <div class="col-md-6 col-lg-2 p-5 mb-2"></div> 
                                    <div class="col-md-6 col-lg-4 p-5 mb-2">
                                    <CheckSquare size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Offer Acceptance</h3>
                                        <p class="card-text">You will receive a letter & offer of acceptance once you get selected, after which your counsellor will go through all the documentation & help you choose the best option. </p>
                                    </div>

                                    <div class="col-md-6 col-lg-4 p-5 mb-2">
                                    <CreditCard size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Student Visa</h3>
                                        <p class="card-text">After accepting the offer, the most important task is to apply for student visa. Our expert team will guide you through visa application process & help you prepare all the documents needed for submission.</p>
                                    </div>
                                    <div class="col-md-6 col-lg-2 p-5 mb-2"></div> 
                                   

                                </div>

                    

                            
                        </div>
                    </section>



                    <section id="contacts-wrap" class="contact-form-wrap" style={{paddingTop : '80px', paddingBottom : '0px'}}>
                        <div class="container">
                        <div class="row country-row">


<div class="col-md-12 section-header mb-5 text-left">
    <h2 class="font-weight-bold text-center">Top Universities</h2>
   

</div>


<div class="col-md-12 col-lg-12 col-sm-12 mb-4 mb-lg-0 text-center">
   
                                    <div data-aos="fade-up" class="">

                                    <Swiper
					spaceBetween={30} centeredSlides={true}
                    slidesPerView={window.screen.availWidth < 300 ? 1 : window.screen.availWidth >= 300 && window.screen.availWidth < 500 ? 2 : window.screen.availWidth >= 500 && window.screen.availWidth < 650 ? 3 : 5}
                    loop={true} autoplay={{
						"delay": 3000,
						"disableOnInteraction": false
					  }} className="mySwiper"
					>
					    {/* <div class="col-md-6 col-lg-6 col-sm-6 mb-2"> */}
           {middle_east.map((dt, index)=>(
               <SwiperSlide>  
            <div class="card text-left mb-2" style={{backgroundColor : 'gainsboro', height: '120px'}}>

<div class="card-body pt-4 pb-4 text-center">
    {/* <i class="eva eva-file-text-outline text-30"></i> */}
    {/* <img src={dt.flag} class='flag-img' style={{display : 'initial'}}/> */}
    <h4 class="card-title pt-2 text-16">{dt.name}</h4>

</div>
</div>
</SwiperSlide>
           ))} 
        
           </Swiper>
        {/* </div> */}
                                    </div>
                                    {/* <div class="slick_control_wrap text-center">

                                    <span type="button" class="ngu_control_button half-button leftRs work-arrow-left btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-left-outline"></i></span>
                                    <button type="button" class="ngu_control_button half-button rightRs work-arrow-right btn btn-circle btn-white btn-shadow"><i class="eva eva-chevron-right-outline"></i></button>
                                </div> */}
</div>
</div>
</div>
                        </section>


                    <section id="subscribe-wrap" class="subscribe p-t-b-80">
                        <div class="container">



                            <div class="row text-center">
                                {/* <!-- start::subscribe-1 --> */}
                                <div data-aos="fade-up" class="col-md-12 aos-init aos-animate">
                                    <div class="subscribe-1">
                                        <div class="card back-color-panel" style={{padding : '40px'}}>
                                            
                                            <div class="card-body">
                                            <div class="row">
                                            <div class="col-md-6">
                                        
                                        Send us a message
                                            <h2 class="font-weight-bold">Best way to start your journey to your dream career abroad. Get your appointment today!</h2>
                                    </div>
                                    <div class="col-md-6" style={{margin : 'auto'}}>
                                        
                                    <div class="intro1Buttons" style={{margin : 'auto'}}>
                                       
                                       <a id="" href="/contactus" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                           Contact us now
                                       </a>

                                   </div>
                                           </div>
                                            </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end::subscribe-1 --> */}
                            </div>

                        </div>
                    </section>


                     {/* <!-- start::contact-form --> */}
 <section id="contact" class="contact-form-wrap light-gray p-t-b-80" style={{ paddingTop: '80px',
    paddingBottom: '80px'}}>
                        <div class="container">

                            <div class="row">

                            <div data-aos="fade-right" class="form-wrap col-md-5 mb-4">
                                {/* <div class="col-lg-6 offset-lg-1"> */}
                                        {/* <h2 class="font-weight-bold">Some attractive benefits provide</h2>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident eveniet
                                            illo,
                                            placeat dolorum delectus nam error itaque ratione facilis quod praesentium.
                                        </p> */}

                                       <img src={'/assets/images/studycontact.png'} /> 
                                    {/* </div> */}
                                    </div>

                            <div data-aos="fade-right" class="form-wrap col-md-1 mb-4"></div>

                                <div data-aos="fade-right" class="form-wrap col-md-6 mb-4">
                               
                                    {/* <div *ngif="success" class="alert alert-success alert-dismissible fade show" role="alert" 
                                    [@animate]="{value:'*',params:{y:'-120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
                                        <button type="button" class="close" onclick="success=false">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <strong>Your Message Sent succefully</strong>
                                    </div> */}


                                   <form class="row form" role="form" onSubmit={(e)=>{
                                        e.preventDefault()
                                    }}>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-person-outline"></i>
                                            </label>
                                            <input type="text" name="Name" id="" class="form-control" placeholder="Your Name" required
                                            value={name}
                                            onChange={(text)=>{
                                                setName(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-email-outline"></i>
                                            </label>
                                            <input type="email" name="email" class="form-control" placeholder="Your email" aria-describedby="helpId" required
                                            value={email}
                                            onChange={(text)=>{
                                                setEmail(text.target.value)
                                            }}
                                            />

                                        </div>
                                       

                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-phone-outline"></i>
                                            </label>
                                            <input type="mobile" required name="mobile" class="form-control" placeholder="Your mobile" aria-describedby="helpId" 
                                            value={mobile}
                                            onChange={(text)=>{
                                                setMobile(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-edit-2-outline"></i>
                                            </label>
                                            {/* <input type="text" required name="subject" class="form-control" placeholder="Your subject here" aria-describedby="helpId" /> */}
                                            <input type="text" required name="subject" class="form-control" value={'Study Abroad'} disabled placeholder="Your subject here" aria-describedby="helpId" />
                                           
                                        </div>
                                        <div class="form-group mb-4 col-md-12">

                                            <label for="">
                                                <i class="eva eva-edit-outline"></i>
                                            </label>
                                            <textarea class="form-control" placeholder="Your message here" name="message" rows="5"
                                            value={message}
                                            onChange={(text)=>{
                                                setMessage(text.target.value)
                                            }}
                                            ></textarea>


                                        </div>

                                        <div class="form-group col-md-12">
                                            <button type="submit"
                                            disabled={buttonLocked}
                                            onClick={()=>{
                                                sendMailer()
                                            }}
                                            class="btn btn-block half-button form-submit-button btn-large btn-gradient">
                                                {buttonLocked ? 'Sending...' : 'Send Message'}
                                                
                                            </button>
                                            <p style={{color : 'red'}}>{errMsg}</p>
                                        </div>



                                    </form>
                                    <div style={{textAlign : 'center', display : sendSuccess ? '' : 'none'}}>
					<p style={{color : 'green', textAlign : 'center'}}><CheckSquare  size={15}/>&nbsp;
					Thank you for your message! We will get back to you soon.</p>
					</div>



                                </div>
                              
                    
                              
                            </div>

                            
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}
                </div>
                {/* <!-- ============ Main content End ============= --> */}




            </div>
        </div>
    </div>
  );
}

export default StudyAbroad;
