import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Header from "./Header";
class Routing extends Component {

   render(){
       return(
        <BrowserRouter>
        <div>
           <Header />
    </div>
    </BrowserRouter>
       )
   }
}

export default Routing;