import { useEffect, useState } from 'react'; 
import { Activity, BookOpen, Briefcase, CheckSquare, DollarSign, Dribbble, Facebook, Globe, Instagram, Mail, MapPin, Menu, Phone, Send, Share2, Target, ThumbsUp, Twitch, Twitter } from 'react-feather'
import moment from 'moment'
import PostApiCall from './Api/Api'
import {Helmet} from "react-helmet";

function JobAbroad() {

       
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [subject, setSubject] = useState('Work Abroad')
    const [message, setMessage] = useState('')
    const [buttonLocked, setButtonLocked] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')




    const sendMailer = () => {
								
											setErrMsg('')
											if(name != '' && email != '' && mobile != '' && subject != '' && message != ''){
												setButtonLocked(true)
												PostApiCall.postRequest({
													name, 
													email,
													mobile,
													subject,
                                                    message
												  }, "QueryMailer").then((results) => results.json().then(obj1 => {
											  
													if (results.status === 200 || results.status === 201) {
														
                                                        setName('')
                                                        setEmail('')
                                                        setMobile('')
                                                        setSubject('')
                                                        setMessage('')
                                                        setSendSuccess(true)
                                                        setButtonLocked(false)
                                                        
													}else {
                                                        setButtonLocked(false)
                                                        setErrMsg('Something wnet wrong, try again later.')
														
													}
												}))


											}
									
    }


  return (
    <div className="App">

<Helmet>
        <title>Expert immigration Agencies for Work Abroad | PR Visa Consultants in Delhi</title>
        <meta name="description" content="ShiftAbroad Expert Immigration Consultants in Delhi as well as provide best consulting services for Canada and top countries, Contact Now at (+91) 981 844 4514" />
        <meta keywords="Registered Agencies for Work Abroad, Abroad Consultancy in Delhi, Abroad Expert immigration, PR Visa Consultants in Delhi, 
Best pr Visa Consultant in Delhi, Chandigarh immigration agents" />
    </Helmet>

    
    <div class="app-landing-wrap landing_wrap">
            <div id="landing_wrap" class="landing-gradient-red-orange">

            
                {/* <!-- ============ Main content start ============= --> */}
                <div class="main-content-wrap">

                    {/* <!-- intro-section --> */}

                    <section id="intro-wrap" class="intro1Wrap1 text-left text-white">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 intro1LeftSection pb-5" style={{margin : 'auto'}}>
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">WORK ABROAD</h1>
                                   
                                   
                                    <div class="intro1Buttons" style={{margin : 'auto'}}>
                                       
                                        <a id="" href="/workabroad/#contact" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                            Contact us now
                                        </a>

                                    </div>

                                </div>
                                <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="/assets/images/jobbanner.png" class="img-responsive" style={{paddingTop : '30px'}} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay">

                        </div>

                    </section>
                    {/* <!-- end::intro-section --> */}

 {/* <!-- start::contact-form --> */}
 <section id="contacts-wrap" class="contact-form-wrap" style={{paddingBottom : '0px', paddingTop : '50px'}}>
                        <div class="container">
                            <div class="row">



                                <div data-aos="fade-right" class="form-wrap col-md-12 mb-4">
                                    <div class="section-header mb-5">
                                        <h2 class="font-weight-bold" style={{marginTop : '0px'}}>Get your dream Job Overseas!</h2>
                                        <p>  ShiftAbroad, with years of experience & vast network strives to provide job opportunities
across various sectors, globally. Since past two decades demand for Indian talents has grown 
tremendously worldwide & this trend is still continuing, bringing more opportunities. We will keep 
updating work opportunities in many sectors & countries. 
</p>

                                    </div>
                                  


                                </div>
                              
                            </div>
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}
 {/* <!-- start::contact-form --> */}
 <section id="" class="right-image-wrap light-gray p-t-b-80" style={{ paddingTop: '80px',
    paddingBottom: '0px'}}>
                        <div class="container">


                        <div class="row text-center">
                                    <div class="col-md-12 mb-5">
                                        <h1 class="font-weight-bold">How it works</h1>
                                        <p class="text-center">Our aligned procedure & structured team with expertise in employment migration will help your get a job overseas best for you</p>

                                    </div>

                                    <div class="col-md-6 col-lg-6 p-5 mb-2">
                                        <Target size={30} class="icon-color-layer" />
                                        <h3 class="font-weight-bold mb-2 mt-2">Lock Scope of Work</h3>
                                        <p class="card-text">First & foremost it is vital to understand the requirement & structurise the costing & timeline plans.</p>
                                    </div>
                                    <div class="col-md-6 col-lg-6 p-5 mb-2">
                                    <Activity size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Create & Track Plans</h3>
                                        <p class="card-text">Initialize the hiring process on behalf of employer on the basis of requirement keeping all the risk & challenges in consideration.</p>
                                    </div>
                                    <div class="col-md-6 col-lg-6 p-5 mb-2">
                                    <Briefcase size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Fulfill Employment Formalities</h3>
                                        <p class="card-text">Taking care of all the legal & security checks related to visa & embassy & help you fulfill all the emploment formalities & documentation.</p>
                                    </div>

                                    <div class="col-md-6 col-lg-6 p-5 mb-2">
                                    <Send size={30} class="icon-color-layer" />

                                        <h3 class="font-weight-bold mb-2 mt-2">Full Onboarding Support</h3>
                                        <p class="card-text">With the handholding support, our team provide ticketing, travel, housing & family support for you to migrate with ease.</p>
                                    </div>

                                   

                                </div>

                    

                            
                        </div>
                    </section>


                    <section id="subscribe-wrap" class="subscribe p-t-b-80">
                        <div class="container">



                            <div class="row text-center">
                                {/* <!-- start::subscribe-1 --> */}
                                <div data-aos="fade-up" class="col-md-12 aos-init aos-animate">
                                    <div class="subscribe-1">
                                        <div class="card back-color-panel" style={{padding : '40px'}}>
                                            
                                            <div class="card-body">
                                            <div class="row">
                                            <div class="col-md-6">
                                        
                                            Send us a message
                                            <h2 class="font-weight-bold">Get flooded with job opportunities overseas in a user friendly manner with us, contact us today!</h2>
                                    </div>
                                    <div class="col-md-6" style={{margin : 'auto'}}>
                                        
                                    <div class="intro1Buttons" style={{margin : 'auto'}}>
                                       
                                       <a id="" href="/contactus" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                           Contact us now
                                       </a>

                                   </div>
                                           </div>
                                            </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end::subscribe-1 --> */}
                            </div>

                        </div>
                    </section>


                     {/* <!-- start::contact-form --> */}
 <section id="contact" class="contact-form-wrap light-gray p-t-b-80" style={{ paddingTop: '80px',
    paddingBottom: '80px'}}>
                        <div class="container">

                            <div class="row">

                            <div data-aos="fade-right" class="form-wrap col-md-5 mb-4">
                                {/* <div class="col-lg-6 offset-lg-1"> */}
                                        {/* <h2 class="font-weight-bold">Some attractive benefits provide</h2>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident eveniet
                                            illo,
                                            placeat dolorum delectus nam error itaque ratione facilis quod praesentium.
                                        </p> */}

<img src={'/assets/images/studycontact.png'} /> 
                                    {/* </div> */}
                                    </div>

                            <div data-aos="fade-right" class="form-wrap col-md-1 mb-4"></div>

                                <div data-aos="fade-right" class="form-wrap col-md-6 mb-4">
                               
                                    {/* <div *ngif="success" class="alert alert-success alert-dismissible fade show" role="alert" 
                                    [@animate]="{value:'*',params:{y:'-120px',opacity:'0',delay:'100ms', duration: '400ms'}}">
                                        <button type="button" class="close" onclick="success=false">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <strong>Your Message Sent succefully</strong>
                                    </div> */}


<form class="row form" role="form" onSubmit={(e)=>{
                                        e.preventDefault()
                                    }}>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-person-outline"></i>
                                            </label>
                                            <input type="text" name="Name" id="" class="form-control" placeholder="Your Name" required
                                            value={name}
                                            onChange={(text)=>{
                                                setName(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-email-outline"></i>
                                            </label>
                                            <input type="email" name="email" class="form-control" placeholder="Your email" aria-describedby="helpId" required
                                            value={email}
                                            onChange={(text)=>{
                                                setEmail(text.target.value)
                                            }}
                                            />

                                        </div>
                                       

                                        <div class="form-group mb-4 col-md-6">
                                            <label for="">
                                                <i class="eva eva-phone-outline"></i>
                                            </label>
                                            <input type="mobile" required name="mobile" class="form-control" placeholder="Your mobile" aria-describedby="helpId" 
                                            value={mobile}
                                            onChange={(text)=>{
                                                setMobile(text.target.value)
                                            }}
                                            />

                                        </div>
                                        <div class="form-group mb-4 col-md-12">
                                            <label for="">
                                                <i class="eva eva-edit-2-outline"></i>
                                            </label>
                                            {/* <input type="text" required name="subject" class="form-control" placeholder="Your subject here" aria-describedby="helpId" /> */}
                                            <input type="text" required name="subject" class="form-control" value={'Study Abroad'} disabled placeholder="Your subject here" aria-describedby="helpId" />
                                           
                                        </div>
                                        <div class="form-group mb-4 col-md-12">

                                            <label for="">
                                                <i class="eva eva-edit-outline"></i>
                                            </label>
                                            <textarea class="form-control" placeholder="Your message here" name="message" rows="5"
                                            value={message}
                                            onChange={(text)=>{
                                                setMessage(text.target.value)
                                            }}
                                            ></textarea>


                                        </div>

                                        <div class="form-group col-md-12">
                                            <button type="submit"
                                            disabled={buttonLocked}
                                            onClick={()=>{
                                                sendMailer()
                                            }}
                                            class="btn btn-block half-button form-submit-button btn-large btn-gradient">
                                                {buttonLocked ? 'Sending...' : 'Send Message'}
                                                
                                            </button>
                                            <p style={{color : 'red'}}>{errMsg}</p>
                                        </div>



                                    </form>
                                    <div style={{textAlign : 'center', display : sendSuccess ? '' : 'none'}}>
					<p style={{color : 'green', textAlign : 'center'}}><CheckSquare  size={15}/>&nbsp;
					Thank you for your message! We will get back to you soon.</p>
					</div>


                                </div>
                              
                    
                              
                            </div>

                            
                        </div>
                    </section>

                    {/* <!-- end::contact-form --> */}
                </div>
                {/* <!-- ============ Main content End ============= --> */}




            </div>
        </div>
    </div>
  );
}

export default JobAbroad;
