import React from "react"
import { BookOpen, Briefcase, DollarSign, Dribbble, Facebook, Globe, Instagram, Mail, MapPin, Menu, Phone, Share2, ThumbsUp, Twitch, Twitter } from 'react-feather'
import moment from 'moment'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./Footer";
import Home from "./Home";
import TopCountries from "./TopCountries";
import ContactUs from "./ContactUs";
import JobAbroad from "./JobAbroad";
import SettleAbroad from "./SettleAbroad";
import StudyAbroad from "./StudyAbroad";
import Aboutus from "./Aboutus";


class Header extends React.Component {
    render() {
        return (
            <div className="App">
                <Router>
                    <div class="app-landing-wrap landing_wrap">
                        <div id="landing_wrap" class="landing-gradient-red-orange">

                            {/* <!--=============== Header start ================--> */}
                            <div class="main-header header-fixed-default" id="home-header">
                                <nav class="navbar container w-100 navbar-expand-lg navbar-transparent bg-transparent">


                                    <div class="logo">
                                        <img src="/assets/images/landing/faces/SAlogo.png" alt="" />
                                    </div>

                                    <div class="menu-toggle navbar-toggler" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>

                                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <div class="d-flex align-items-center">


                                        </div>

                                        <div style={{ margin: 'auto' }}></div>

                                        <div class="header-part-right">

                                            <ul class="navbar-nav mr-auto">
                                                <li class="nav-item active">
                                                    <a class="nav-link m-2" href="/">Home</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link m-2" href="/aboutus">About</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link m-2 m-2">Offerings</a>
                                                    {/* <!-- First  Drop Down --> */}
                                                    <ul>
                                                        <li class="nav-item">
                                                            <a href="/studyabroad" class="menu-item">Study Abroad</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="/workabroad" class="menu-item">Work Abroad</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="/settleabroad" class="menu-item">Settle Abroad</a>
                                                        </li>



                                                    </ul>
                                                </li>
                                                {/* <!-- drop down menu start --> */}
                                                <li class="nav-item">
                                                    <a href="/topcountries" class="nav-link m-2">Top Countries </a>

                                                </li>
                                                {/* <!-- drop down menu end --> */}


                                                <li class="nav-item">
                                                    <a class="nav-link m-2" href="/contactus">Contact Us</a>
                                                </li>

                                                <li class="nav-item">
                                                    {/* <a class="scroll-to nav-link m-2" href="tel:+919971185324">
                                                        <Phone size={15} style={{ verticalAlign: 'middle', color: 'blue' }} />
                                                        <span style={{ color: '#fff' }}>  (+91) 997 118 5324 </span>
                                                    </a> */}
                                                    <a class="scroll-to nav-link m-2" href="tel:+919818444514">
                                                        <Phone size={15} style={{ verticalAlign: 'middle', color: 'blue' }} />
                                                        <span style={{ color: '#fff' }}>  (+91) 981 844 4514 </span>
                                                    </a>
                                                </li>



                                                <li class="nav-item">
                                                    <a class="scroll-to nav-link m-2" href="mailto:shift@shiftabroad.in">
                                                        <Mail size={15} style={{ verticalAlign: 'middle', color: 'blue' }} />
                                                        <span style={{ textTransform: 'lowercase', color: '#fff' }}> shift@shiftabroad.in</span>
                                                    </a>
                                                </li>
                                            </ul>



                                        </div>
                                    </div>
                                </nav>

                            </div>

                            {/* <!--=============== Header End ================--> */}


                        </div>
                    </div>
                </Router>
                <Switch>
                    <Route exact path={"/"} component={Home} />
                    <Route exact path={"/topcountries"} component={TopCountries} />
                    <Route exact path={"/contactus"} component={ContactUs} />
                    <Route exact path={"/workabroad"} component={JobAbroad} />
                    <Route exact path={"/settleabroad"} component={SettleAbroad} />
                    <Route exact path={"/studyabroad"} component={StudyAbroad} />
                    <Route exact path={"/aboutus"} component={Aboutus} />


                </Switch>
                {this.props.children}
                <Footer />
            </div>
        );
    }
}

export default Header;
