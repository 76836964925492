import { Anchor, BookOpen, Briefcase, DollarSign, Dribbble, Eye, Facebook, Globe, Instagram, Mail, MapPin, Menu, Navigation, Phone, Share2, Target, ThumbsUp, Twitch, Twitter } from 'react-feather'
import moment from 'moment'
import {Helmet} from "react-helmet";

function Aboutus() {
  return (
    <div className="App">
         <Helmet>
        <title>About Us - ShiftAbroad</title>
        <meta name="description" content="ShiftAbroad’s concept & ethics focuses not only on the business model of helping people create a new life abroad." />
    </Helmet>
    
    <div class="app-landing-wrap landing_wrap">
            <div id="landing_wrap" class="landing-gradient-red-orange">

            
                {/* <!-- ============ Main content start ============= --> */}
                <div class="main-content-wrap">

                    {/* <!-- intro-section --> */}

                    <section id="intro-wrap" class="intro1Wrap1 text-left text-white">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 intro1LeftSection pb-5" style={{margin : 'auto'}}>
                                    <h1 class="font-weight-bold text-white text-42 mb-3 t-shadow">ABOUT US</h1>
                                 

                                </div>
                                <div class="col-md-6 intro1RightSection d-flex align-items-center">

                                    <div class="intro1ProductImage">

                                        <img src="/assets/images/about.png" class="img-responsive" alt="" style={{paddingBottom : '20px', paddingTop : '20px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay">

                        </div>

                    </section>
                    {/* <!-- end::intro-section --> */}


                    {/* <!-- best --> */}
                    <section class="best-wrap" id="extra-feature-wrap" style={{paddingTop : '50px'}}>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 section-header mb-5">
                                    <h2 class="font-weight-bold"> Who We Are</h2>
                                    <p>ShiftAbroad’s concept & ethics focuses not only on the business model of helping people create a new life
abroad but also a genuine approach that will stick around across all the phases & beyond.<br/>
</p>

                                </div>

                                <div data-aos="fade-right" class="col-md-4 mb-4">
                                    <div class="card o-hidden text-left">

                                        <div class="card-body" style={{height : '192px'}}>
                                            <div class="left_icon float-left w-20 p-3 text-center">
                                                <Eye size={130} className='icon-about-rev' style={{position : 'absolute', left : '-10%', top: '16%', color : 'lightseagreen'}} />
                                            </div>
                                            <div class="right_content w-70 float-left p-3">
                                                <h4 class="card-title font-weight-bold">Vision</h4>
                                                <p class="card-text">We help you contruct your dream into reality.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end of col --> */}
                                <div data-aos="fade-left" class="col-md-4 mb-4">
                                    <div class="card o-hidden text-left">

                                        <div class="card-body" style={{height : '192px'}}>
                                            <div class="left_icon float-left w-20 p-3 text-center">
                                            <Target size={130} style={{position : 'absolute', left : '-10%', top: '16%', color : 'lightseagreen'}} />

                                            </div>
                                            <div class="right_content w-70 float-left p-3">
                                                <h4 class="card-title font-weight-bold">Mission</h4>
                                                <p class="card-text">We aim to support individuals & families move from their country of origin to their country of choice. </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end of col --> */}
                                <div data-aos="fade-up" class="col-md-4 mb-4 mb-lg-0 mb-md-0">
                                    <div class="card o-hidden text-left">

                                        <div class="card-body" style={{height : '192px'}}>
                                            <div class="left_icon float-left w-20 p-3 text-center">
                                            <Anchor size={130} style={{position : 'absolute', left : '-10%', top: '16%', color : 'lightseagreen'}} />

                                            </div>
                                            <div class="right_content w-70 float-left p-3">
                                                <h4 class="card-title font-weight-bold">Purpose</h4>
                                                <p class="card-text">We empower our clients with information, legal advice and logistic support.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </section>
                    {/* <!-- end::best --> */}


                    
                    {/* <!-- start-services --> */}
                    <section id="services-wrap" class="services-caurosel-wrap light-gray">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 section-header mb-5">
                                    <h2 class="font-weight-bold">Our Services</h2>
                                    <p>At ShiftAbroad, we ease our client’s journey to move to a country of their choice. We are a team of highly experienced professionals who have been in the industry for more than two decades. This dedicated team helps individuals with customised advice in accordance with the legal framework of the country they choose. We mentor to help, make informed to take decisions based on the legal, economic and social requirements of the prospective country of residence. We have pool of experienced team with experience of Embassies, Travel & Recruitment Agencies.
<br/><br/>
We support all opportunities to migrate
</p>

                                </div>
                                <div class="col-md-4">

                                    <div data-aos="fade-up" class="">
                                        {/* <!-- start slider item --> */}
                                        <div class="service-wrap">

                                            <div class="card mb-4">

                                                <div class="card-header p-0 text-left">

                                                    <img class="img-responsive" src="/assets/images/aboutstudy.jpg"/>
                                                </div>
                                                <div class="card-body text-left">

                                                    <h3 class="card-title text-capitalize font-weight-bold">Study Abroad
                                                    </h3>
                                                    <p class="card-text">ShiftAbroad will help you to find various universities worldwide & get you to the 
city of your choice. <br/>We are pioneers in providing one stop solution for your all-global study requirements.  
Our association with many reputed institutions worldwide would be guiding & assisting 
you in all the processes. 
</p>
                                                    {/* <!-- <a href="#" class="text-primary text-capitalize font-weight-medium"> <span>Read More</span> </a> --> */}
                                                </div>
                                                <div class="card-footer pl-3 text-left">
                                                   
                                                    <a href="/studyabroad" class="btn btn-lg p-1 mr-1 text-dark">
                                                    <span><Navigation size={15} style={{color : 'lightseagreen'}}></Navigation></span>

                                                    </a>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        {/* <!-- end of slider item --> */}
                                       
                                      

                                    </div>

                                  






                                </div>
                                <div class="col-md-4">

<div data-aos="fade-up" class="">
    {/* <!-- start slider item --> */}
    <div class="service-wrap">

        <div class="card mb-4">

            <div class="card-header p-0 text-left">

                <img class="img-responsive" src="/assets/images/aboutjob.png"/>
            </div>
            <div class="card-body text-left">

                <h3 class="card-title text-capitalize font-weight-bold">Work Abroad
                </h3>
                <p class="card-text">ShiftAbroad, with years of experience & vast network strives to provide job opportunities
across various sectors, globally.<br/> Since past two decades demand for Indian talents has grown 
tremendously worldwide & this trend is still continuing, bringing more opportunities. We will keep 
updating work opportunities in many sectors & countries. 
</p>
                {/* <!-- <a href="#" class="text-primary text-capitalize font-weight-medium"> <span>Read More</span> </a> --> */}
            </div>
            <div class="card-footer pl-3 text-left">
               
                <a href="/workabroad" class="btn btn-lg p-1 mr-1 text-dark">
                <span><Navigation size={15} style={{color : 'lightseagreen'}}></Navigation></span>

                </a>
            </div>
        </div>
        
    </div>
    {/* <!-- end of slider item --> */}
   
  

</div>








</div>
<div class="col-md-4">

<div data-aos="fade-up" class="">
    {/* <!-- start slider item --> */}
    <div class="service-wrap">

        <div class="card mb-4">

            <div class="card-header p-0 text-left">

                <img class="img-responsive" src="/assets/images/aboutsettle.jpg"/>
            </div>
            <div class="card-body text-left">

                <h3 class="card-title text-capitalize font-weight-bold">Settle Abroad
                </h3>
                <p class="card-text">ShiftAbroad will help you find a country of your choice. We will advise you best based on our experience & market analysis on various subjects such as intergovernmental, non-governmental organizations, stakeholders, facilities, economic & social development, migration related programmes aimed at maximizing migrations benefits.</p>
                {/* <!-- <a href="#" class="text-primary text-capitalize font-weight-medium"> <span>Read More</span> </a> --> */}
            </div>
            <div class="card-footer pl-3 text-left">
                <a href="/settleabroad" class="btn btn-lg p-1 mr-1 text-dark">
                    <span><Navigation size={15} style={{color : 'lightseagreen'}}></Navigation></span>
                </a>
              
            </div>
        </div>
        
    </div>
    {/* <!-- end of slider item --> */}
   
  

</div>








</div>

                            </div>

                        </div>

                    </section>
                    {/* <!-- end-services --> */}


                    {/* <!-- start::left-img --> */}
                    <section id="product-details-left-image-wrap" class="product-details-left-image p-t-b-80" style={{paddingTop : '80px'}}>
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-7 section-header mb-5">
                                    <h2 class="font-weight-bold">Why Choose Us</h2>
                                    <p>At Shiftabroad we will help you to Shift abroad without any hassle. We are a team of highly experienced professionals who have worked in the industry for more than two decades (20 Years). 
                                        <br/>This dedicated team will provide you with customised advice based on the current legal framework of the country you are planning to resettle.<br/><br/> This team will mentor you to make informed decisions based on your requirements and future projections/visions of your prospective country of residence - legal, economical and social. We are committed to your satisfaction and happiness.</p>

                                </div>
                                {/* <div class="col-md-5 mb-4 mb-lg-0">
                                    <img data-aos="fade-right" src="../assets/images/landing/service-3/service_3-1.svg" alt="" />
                                </div> */}
                                <div class="col-md-4 offset-md-1">

                                    <div data-aos="fade-right" class="product-details-li__content d-flex flex-row align-items-start pl-5 mb-4">
                                        <div class="product-details__icons mr-4">
                                            <span class="rounded-icon bg-info">
                                                <i class="eva eva--white eva-smartphone-outline align-middle text-25"></i>
                                            </span>
                                        </div>
                                        <div class="feature-content" style={{marginTop : '10px'}}>
                                            <h4 class="font-weight-bold">Knowledge</h4>
                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                veniam.
                                            </p> */}
                                        </div>
                                    </div>
                                    <div data-aos="fade-left" class="product-details-li__content d-flex flex-row align-items-start pl-5 mb-4">
                                        <div class="product-details__icons mr-4">
                                            <span class="rounded-icon bg-danger">
                                                <i class="eva eva--white eva-checkmark-circle-2-outline align-middle text-25"></i>
                                            </span>
                                        </div>
                                        <div class="feature-content" style={{marginTop : '10px'}}>
                                            <h4 class="font-weight-bold">Transparency</h4>
                                           
                                        </div>
                                    </div>
                                    <div data-aos="fade-left" class="product-details-li__content d-flex flex-row align-items-start pl-5 mb-4">
                                        <div class="product-details__icons mr-4">
                                            <span class="rounded-icon bg-danger">
                                                <i class="eva eva--white eva-checkmark-circle-2-outline align-middle text-25"></i>
                                            </span>
                                        </div>
                                        <div class="feature-content" style={{marginTop : '10px'}}>
                                            <h4 class="font-weight-bold">Expertise</h4>
                                           
                                        </div>
                                    </div>
                                   
                                    <div data-aos="fade-up" class="product-details-li__content d-flex flex-row align-items-start pl-5 mb-4 mb-lg-0">
                                        <div class="product-details__icons mr-4">
                                            <span class="rounded-icon bg-success">
                                                <i class="eva eva--white eva-shield-outline align-middle text-25"></i>
                                            </span>
                                        </div>
                                        <div class="feature-content" style={{marginTop : '10px'}}>
                                            <h4 class="font-weight-bold">Pricing </h4>
                                            
                                        </div>
                                        
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- end::left-img --> */}

                    <section id="subscribe-wrap" class="contact-form-wrap light-gray p-t-b-80" style={{paddingTop : '80px'}}>
                        <div class="container">



                            <div class="row text-center">
                                {/* <!-- start::subscribe-1 --> */}
                                <div data-aos="fade-up" class="col-md-12 aos-init aos-animate">
                                    <div class="subscribe-1">
                                        <div class="card back-color-panel" style={{padding : '40px'}}>
                                            
                                            <div class="card-body">
                                            <div class="row">
                                            <div class="col-md-6">
                                        
                                        Send us a message
                                            <h2 class="font-weight-bold">Grab what is best for you in a country of your choice.</h2>
                                    </div>
                                    <div class="col-md-6" style={{margin : 'auto'}}>
                                        
                                    <div class="intro1Buttons" style={{margin : 'auto'}}>
                                       
                                       <a id="" href="/contactus" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase" role="button">

                                           Contact us now
                                       </a>

                                   </div>
                                           </div>
                                            </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end::subscribe-1 --> */}
                            </div>

                        </div>
                    </section>
                    {/* <!-- end::contact-form --> */}
                </div>
                {/* <!-- ============ Main content End ============= --> */}




            </div>
        </div>
    </div>
  );
}

export default Aboutus;
